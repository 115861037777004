import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SecurityService } from '../services/security.service';
import { CategoryService } from '../services/category.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pap',
  templateUrl: './pap.component.html',
  styleUrls: ['./pap.component.scss'],
})
export class PapComponent implements OnInit, OnDestroy {
  @ViewChild('modal') modal: ElementRef;
  @ViewChild('appContainer', { static: true }) appContainer: ElementRef;
  @ViewChild('terms') terms: ElementRef;

  backRoute = '/app/finish';
  nextRoute = '/app/finishPap';
  newRoute = '';
  wowDisplay = false;
  isTimeout = false;
  hasValidationPerson = false;
  showModalValue = false;
  buttonNextModal = false;

  form: FormGroup;
  errorMessage: string = '';
  isNextButtonEnabled = false;
  isLoading = false;
  timer;
  quotaTxt = '';
  quota;
  durationTxt = '';
  duration;
  targetTxt = '';
  target;
  principalActivityTypeId: number;
  showTermsC = false;
  showHeaderText = true;
  categories: any[] = [];
  motiveValidate = false;
  months: any[] = [];
  disabledDuration = false;
  maxValueInput:number = 0;
  minValueInput:number = 0;

  // Inputs para el componente ModalComponent
  imagePath = 'assets/svg/Pig.svg';
  h2Text = 'Confirma tu ahorro';
  quotaText;
  durationText;
  methodText;
  targetText;
  noteText = '';
  buttonNextText = 'Comenzar a ahorrar';
  buttonBackText = 'Atrás';
  payMethodId: number | any = 1;
  targetEnable: boolean = false;
  papData: any = {};
  idCategory: number;
  nombreDelPAP: string;
  closestItems = [];
  quotaSubscription: Subscription;
  durationSubscription: Subscription;
  

  constructor(
    private router: Router,
    private title: Title,
    private securityService: SecurityService,
    private _categoryService: CategoryService
  ) {
    this.title.setTitle('Onboarding Digital | PAP');
    this.form = new FormGroup({
      quota: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.max(999999999),
        ])
      ),
      duration: new FormControl('', Validators.compose([Validators.required])),
      targetId: new FormControl('', Validators.compose([Validators.required])),
      paymentMethodId: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      savingCategoryId: new FormControl('', Validators.compose([Validators.required])),
    });

    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 20000);

    this.form.valueChanges.subscribe(() => {
      this.updateNextButtonState();
    });
  }

  getSelectedDuration() {
    this.duration = this.form.get('duration').value;

    return (this.durationText = `${this.form.get('duration').value}`);
  }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if (route && currentUrl !== route) {
      this.router.navigateByUrl(currentUrl);
    }
    this._categoryService.getCategories().subscribe(
      (response) => {
        this.categories = response.savingCategories;
    
        if (this.categories.length > 0) {
          const minMaxValues = this.categories.reduce(
            (acc, category) => {
              return {
                minSaving: Math.min(acc.minSaving, category.minSaving),
                maxSaving: Math.max(acc.maxSaving, category.maxSaving),
              };
            },
            {
              minSaving: this.categories[0].minSaving,
              maxSaving: this.categories[0].maxSaving,
            }
          );
          this.minValueInput = minMaxValues.minSaving;
          this.maxValueInput = minMaxValues.maxSaving;
        } else {
          console.error('No hay categorias');
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );
    await new Promise((resolve, reject) => {
      this.securityService.getPrincipalActivityTypeId().subscribe({
        next: async (response: any) => {
          this.principalActivityTypeId = response.principalActivityTypeId;
          if (this.principalActivityTypeId === 2) {
            this.form.get('paymentMethodId')!.setValue(1);
            this.showHeaderText = false;
          }
          resolve(response);
        },
        error: async (error: any) => {
          reject(error);
        },
      });
    });
    this.filterQuota();
    this.filterTargets();
  }
  
  filterQuota(){
    let filteredItems: any[] = [];
    this.form.get('duration').disable();
    if(this.quotaSubscription){
      this.quotaSubscription.unsubscribe();
    }
    this.quotaSubscription = this.form.get('quota')?.valueChanges.subscribe((newQuotaValue) => {
      let minPositiveDifference = Number.MAX_VALUE;
      if (this.form.get('quota').valid) {
        this.form.get('duration').enable();
        const quotaValue = this.form.get('quota')?.value;

        this.closestItems = [];
        minPositiveDifference = Number.MAX_VALUE;

        for (let i = 0; i < this.categories.length; i++) {
          const difference = quotaValue - this.categories[i].minSaving;

          if (difference >= 0 && difference < minPositiveDifference) {
            minPositiveDifference = difference;
            this.closestItems = [this.categories[i]];
          } else if (difference === minPositiveDifference) {
            this.closestItems.push(this.categories[i]);
          }
        }
      }
      const months = [];
      for (const category of this.closestItems) {
        const minDuration = category.minDuration;
        const maxDuration = category.maxDuration;
        this.months = [];
        for (let i = minDuration; i <= maxDuration; i++) {
          months.push(i);
        }
      }
      this.months = months.reduce((acc, valor) => {
        if (!acc.includes(valor)) {
          acc.push(valor);
        }
        return acc;
      }, []);
    });
  }

  filterTargets() {
    if (this.durationSubscription) {
      this.durationSubscription.unsubscribe();
    }
    this.durationSubscription = this.form.get('duration')?.valueChanges.subscribe((newQuotaValue) => {
      this.setTarget(null, false);
      const duration = this.form.get('duration').value;
      if(this.closestItems.length > 0){
        const validCategories = this.closestItems.filter(
          (category) => category.minDuration <= duration
        );
        if(validCategories.length > 0){
          let finalCategory = validCategories[0];
          for (const category of validCategories) {
            if(category.minDuration >= finalCategory.minDuration){
              this.maxValueInput = category.maxSaving
              finalCategory = category;
              this.enableTargets(finalCategory);
            }
          }
        }
      }
    });
  }

  enableTargets(finalCategory: any){
    this.form.get('savingCategoryId')!.setValue(finalCategory.id);
    this.idCategory = finalCategory.id;
    this.targetEnable = true;
    this.nombreDelPAP = finalCategory.productName;
  }

  updateNextButtonState() {
    this.isNextButtonEnabled =
      this.form.get('quota')?.valid &&
      this.form.get('duration')?.valid &&
      this.form.get('targetId')?.valid &&
      this.form.get('paymentMethodId')?.valid &&
      this.form.get('savingCategoryId')?.valid;
  }

  async next() {
    switch (this.payMethodId) {
      case 1:
        localStorage.setItem('route', this.nextRoute);
        this.router.navigateByUrl(this.nextRoute);
        break;
      case 2:
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = this.newRoute;
        break;
      case 3:
        localStorage.setItem('route', this.newRoute);
        this.router.navigateByUrl(this.newRoute);
        break;
    }
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  failMessage(message, reload = false) {
    this.errorMessage = message;
    this.isLoading = false;
    this.isNextButtonEnabled = false;
  }

  retry() {
    this.errorMessage = '';
  }

  skip() {
    const quota = this.form.get('quota').value;
    const duration = Number(this.form.value.duration);
    const targetId = this.form.value.targetId;
    const paymentMethodId = this.form.value.paymentMethodId;
    const authorization = this.form.value.authorization;
    const savingCategoryId = this.form.value.savingCategoryId;
    this.papData = {
      quota,
      duration,
      targetId,
      paymentMethodId,
      authorization,
      savingCategoryId,
    };
    this.isNextButtonEnabled = false;
    //this.saveData(this.papData);
  }

  async onSubmit(): Promise<void> {
    this.showModalValue = false;
    this.isLoading = true;
    const quota = this.form.get('quota').value;
    const duration = Number(this.form.get('duration').value);
    const targetId = this.form.get('targetId').value;
    const savingCategoryId = this.form.get('savingCategoryId').value;

    this.papData = {
      quota: quota,
      duration: duration,
      targetId: targetId,
      paymentMethodId: this.payMethodId,
      authorization: this.form.value.authorization,
      savingCategoryId: savingCategoryId,
    };

    try {
      await this.saveData(this.papData);
      this.isLoading = false;

      this.next();
    } catch (error) {
      console.error('Error saving data', error);
      this.failMessage('Error al guardar los datos');
    }
  }

  private async saveData(papData: any) {
    return new Promise((resolve, reject) => {
      this.securityService.setPapData(this.papData).subscribe({
        next: async (response: any) => {
          resolve(response);
        },
        error: async (error: any) => {
          reject(error);
        },
      });
    });
  }

  setTarget($event: any, canContinue: boolean) {
    if(canContinue){
      this.form.get('targetId')!.setValue($event.id);
      this.form.get('targetId')!.enable();
      this.target = this.form.get('targetId').value;
      this.targetText = `${$event.name}`;
      this.updateNextButtonState();
    } else {
      this.form.get('targetId')!.reset();
      this.idCategory = 0;
      this.targetEnable = false;
      this.nombreDelPAP = "";
    }
  }

  getData() {
    const quota = this.form.value.quota;
    const formatQuota = this.formatQuota(quota);
    this.quotaText = `${formatQuota}`;
    const duration = this.form.value.duration;
    this.durationText = ` ${duration} meses`;
    if (this.principalActivityTypeId == 2) {
      this.methodText = `descuento de nomina`;
      this.noteText =
        'Tu ahorro será descontado de tu nómina junto al aporte social.';
    } else {
      this.methodText = `${this.form.get('paymentMethodId').value}`;
      this.noteText =
        'Pagarás la primera cuota, para las siguientes podrás hacerlo desde nuestras oficinas con caja, Portal Transaccional o página web.';
    }
  }
  formatQuota(quota: number): string {
    return quota.toLocaleString('es-ES');
  }

  setQuota($event: any) {
    this.form.get('quota')!.setValue($event);
    this.form.get('duration')!.reset();
    this.setTarget(null, false);
    if(!this.form.get('quota').valid){
      this.form.get('duration').disable();
      this.form.get('targetId').disable();
    }
    this.quotaTxt = $event;
    this.quota = this.quotaTxt = $event;
    this.updateNextButtonState();
  }

  setPaymentMethod($event: {
    id: number | null;
    url: string | null;
    name: string | null;
  }) {
    this.form.get('paymentMethodId')!.setValue($event.name);
    this.payMethodId = $event.id;
    this.newRoute = $event.url;
  }

  showModal() {
    this.getData();
    this.showModalValue = true;
    this.isNextButtonEnabled = false;
    this.appContainer.nativeElement.style.fiOnDestroylter = 'blur(5px)';
    this.appContainer.nativeElement.style.pointerEvents = 'none';
  }

  hideModal() {
    this.showModalValue = false;
    this.appContainer.nativeElement.style.filter = ''; // Elimina el desenfoque
    this.appContainer.nativeElement.style.pointerEvents = '';
  }

  setModal($event: any) {
    if ($event.state == true) {
      this.onSubmit();
      this.buttonNextModal = true;
    } else {
      this.hideModal();
      this.isNextButtonEnabled = true;
    }
  }

  ngOnDestroy(): void {
    this.quotaSubscription?.unsubscribe();
    this.durationSubscription?.unsubscribe();
  }
}
