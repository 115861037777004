<div class="flex items-center px-3 my-[5px] h-[70px] w-[300px] bg-white flex-row justify-between" [ngClass]="{'bg-selected' : state === 'large'}" [@grow]="state" (click)="toggleSize()">
    <div class="row w-100" >
        <div class="col-4 flex items-center justify-center">
            <img [src]="icon" class="max-w-full" style="height: 50%;" />
        </div>
        <div class="col-6 d-flex align-items-center">
            <div class="fluid-container w-100">
                <div class="row">
                    <div class="col text-start text-[#fffcf1] text-base">
                        {{ firstLine }}
                    </div>
                </div>
                <div class="row">
                    <div class="col text-start text-blue text-lg font-bold">
                        {{secondLine}}
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-2 d-flex align-items-center" *ngIf="link.length > 3">
            <img src="assets/corp/plus.png" (click)="goToVideo()"/>
        </div> -->
    </div>
</div>