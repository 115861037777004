import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class OlimpiaService {

  constructor(private httpClient: HttpClient) { }

  getValidation(tipoDoc: string, numDoc: string, email: string, celular: string) {
    return this.httpClient.post(`${ environment.serverUrl }/app/olimpia/validateRequest`, {tipoDoc, numDoc, email, celular});
  }

  verifyValidation(processGuid: string) {
    return this.httpClient.post(`${ environment.serverUrl }/app/olimpia/verifyValidate`, {processGuid});
  }
}
