<app-header
[backRoute]="backRoute"
[showBack]="false"
></app-header>
  <div class="h-[90vh] flex flex-col items-center justify-start text-center bg-light-gray pt-[60px]">
    <div class="container flex flex-col justify-center items-center">
        <div class="flex flex-col justify-center items-center">
          <img src="assets/svg/PulgarArriba.svg" class="logo-felicitaciones" alt="Logo Felicitaciones" />     
          <h1 class="my-4 text-dark-blue font-bold text-2xl">¡Felicitaciones!</h1>          
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center ">
            <p class="text-xs font-thin m-[10px]">Diste el primer paso hacia el cumplimiento de tus propósitos. En el Portal Transaccional podrás consultar el saldo y fecha de corte de tu ahorro, lo encontrarás como <b class="text-dark-blue font-bold">{{savingCategory}}</b>.</p>
        </div>
      </div>
      <div class="row p-3 w-full ">
        <div class="content ml-5">
            <p class="text-left text-xs m-0">Cuota: <b class="text-dark-blue">${{quota}}</b></p>
            <p class="text-left text-xs m-0">Duración: <b class="text-dark-blue">{{duration}} Meses</b></p>
            <p class="text-left text-xs m-0">Motivo: <b class="text-dark-blue">{{target}}</b></p>
            <p class="text-left text-xs m-0">Método de pago: <b class="text-dark-blue">Descuento nómina</b></p>
        </div>
        <div class="offset-md-6 col-md-5 text-end flex justify-center items-center">
          <div class="row w-full pt-3">
            <button type="button" (click)="finish()" class="btn-custom mt-2">
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  