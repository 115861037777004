<app-header
[backRoute]="backRoute"
></app-header>
<div *ngIf="showModalValue" #modal>
  <app-modal
  [imagePath]="imagePath"
  [h2Text]="h2Text"
  [showInfo] = true
  [targetText] = "targetText"
  [quotaText] = "quotaText"
  [durationText] = "durationText"
  [methodText] = "methodText"
  [noteText]="noteText"
  [buttonNextText]="buttonNextText"
  [buttonBackText]="buttonBackText"
  [showTermsC] = "true"
  [nextRoute] = "nextRoute"
  [nombreDelPAP] = 'nombreDelPAP'
  [showHeaderText] = "showHeaderText"
  (modalState)="setModal($event)"
  [disableButton] = "buttonNextModal"

  ></app-modal>
  </div>
  <div class="h-[90vh] flex flex-col items-center text-center bg-gray-200 overflow-y-scroll pt-8 px-2" #appContainer>
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center text-blue font-bold text-2xl text-left pb-4">
          <p>
          ¡Camina hacia tus propósitos ahorrando!
        </p>
        </div>
        <p class="text-start text-[12px] pb-3">
          Abre tu <b>Plan de Ahorro Programado</b> para romper tus propios records, éste será un ahorro adicional a tu aporte social.
        </p>
      </div>
      <div class="row">
        <div class="col text-start">
          <form [formGroup]="form">
            <div class="mb-3">
              <app-field-number 
              [fieldPlaceholder]="'Ingresa la cuota'"
              [minValidValue]="minValueInput" 
              [maxValidValue]="maxValueInput"
              [defaultValue]="form.get('quota')!.value" 
              fieldLabel="Cuota de ahorro (mensual)" 
              (fieldValue)="setQuota($event)">
              </app-field-number>
            </div>
            
            <div class="mb-3">
              <label for="duration" class="form-label text-blue">Duración del ahorro</label>
              <select formControlName="duration" class="form-control" id="duration" [disabled]="disabledDuration">
                <option value="">- Selecciona el plazo -</option>
                <option *ngFor="let month of months" [value]="month">{{ month }} meses</option>
              </select>                         
              <div
                *ngIf="
                  (form.get('duration')?.touched || isTimeout) &&
                  form.get('duration')?.invalid
                "
                id="durationHelp"
                class="text-custom-red"
              >
                Debes seleccionar un plazo.
              </div>
              
              <app-target class=""
              [disabled]="targetEnable"
              [label]="'Motivo'"
              [defaultValue]="form.get('targetId')!.value"
              (valueState)="setTarget($event, true)"
              [idCategory]="idCategory"
            ></app-target>
              <div
              
              >
              <div *ngIf="principalActivityTypeId!=2">
                <label for="paymentMethod" class="form-label text-blue">Métodos de pago</label>
                <app-payment-methods (valueState)="setPaymentMethod($event)"></app-payment-methods>
              </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div>
        <button
          type="button"
          [disabled]="!isNextButtonEnabled || isLoading"
          class="btn btn-primary btn-custom"
          (click)="showModal()"
        >
          Inicia tu ahorro
        </button>
        <br />
      </div>
    </div>
  </div>
  <app-waiting *ngIf="isLoading"></app-waiting>
  <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>
  