<div class="px-3 text-white h-[10vh] flex flex-row justify-between items-center body-blue">
  <div class="" (click)="back()">Regresar</div>
  <div class="h-[10vh] flex justify-end items-center float-right">
    <img src="assets/svg/logo-blanco.svg" class="h-[50px] float-right" alt="Logo Fincomercio" />
  </div>
</div>
  <div class="h-[90vh] flex flex-col items-center text-center bg-gray-200 overflow-y-scroll pt-8 px-2">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center my-4 text-blue font-bold text-2xl">
            Algo más de información...
        </div>
      </div>
  
      <div class="row">
        <div class="col text-start">
              
                    <form>
                        <div class="mb-3">
                            <label for="address" class="form-label text-blue">¿Cuál es tu salario?</label>
                            <input type="text" class="form-control" id="address" aria-describedby="addressHelp">
                            <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>  
                        <div class="mb-3">
                            <label for="address" class="form-label text-blue">Nombre de la empresa donde trabajas</label>
                            <input type="text" class="form-control" id="address" aria-describedby="addressHelp">
                            <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>  
                        <div class="mb-3">
                            <label for="address" class="form-label text-blue">Cargo</label>
                            <input type="text" class="form-control" id="address" aria-describedby="addressHelp">
                            <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>  
                        <div class="mb-3">
                          <label for="ocupation" class="form-label text-blue">¿Cuál es tu nivel de estudios?</label>
                          <select class="form-select" aria-label="Ocipación" id="ocupation">
                            <option selected>Selecciona...</option>
                            <option value="1">Bachiller</option>
                            <option value="2">Pregrado</option>
                            <option value="3">Postgrado</option>
                          </select>
                          <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>
                        <div class="mb-3">
                          <label for="ocupation" class="form-label text-blue">¿Cuál es tu estado civil?</label>
                          <select class="form-select" aria-label="Ocipación" id="ocupation">
                            <option selected>Selecciona...</option>
                            <option value="1">Soltero</option>
                            <option value="2">Casado</option>
                            <option value="3">Unión Libre</option>
                            <option value="3">Separado</option>
                          </select>
                          <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>
                        <div class="mb-3">
                          <label for="department" class="form-label text-blue">¿Eres mujer cabeza de familia?</label>
                          <select class="form-select" aria-label="Departamento" id="department">
                            <option selected value="1">No</option>
                            <option value="2">Sí</option>
                          </select>
                          <!-- <div id="emailHelp" class="form-text"></div> -->
                        </div>
                      </form>


        </div>
      </div>
  
      <div>
        <button
          type="button"
          class="btn btn-primary"
          (click)="wow()"
          style="margin-top: 2em"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
  <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>