<div class="block fixed top-0 left-0 w-full h-full"  style="background: linear-gradient(180deg, rgba(0,117,255,1) 0%, rgba(0,58,162,1) 100%) !important;">
</div>

<div class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl text-white text-center">
  Espera un momento, estamos validando tu información.
    <br>
    <div id="loading-container">
        <div class="loading-circle"></div>
        <div class="loading-circle"></div>
        <div class="loading-circle"></div>
        <div class="loading-circle"></div>
      </div>      
</div>