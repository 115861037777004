import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListService } from '../../services/list.service';

@Component({
  selector: 'app-profession',
  templateUrl: './profession.component.html',
  styleUrl: './profession.component.scss'
})
export class ProfessionComponent {

  @Input() options: any[] = [];
  @Input() label: string = '';
  @Input() defaultValue: any; 
  @Input() placeholder: string = 'Escribe para filtrar...';
  @Output() selectionChange = new EventEmitter<any>();

  inputValue: string = ''; 
  filteredOptions: any[] = [];

  constructor(private listService:ListService) {}

  ngOnInit(): void {
    this.listService.getCodpro()
      .subscribe({
        next: (response: any) => {
          this.options = response.list;
          this.setCodPro(this.defaultValue)
        }
      });
  }

  filterOptions(): void {
    const filterValue = this.inputValue.toLowerCase();
    this.filteredOptions = this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  selectOption(option: any): void {
    this.inputValue = option.name;
    this.filteredOptions = [];
    this.selectionChange.emit(option.id);
  }

  setCodPro(id: any) {
    const selectedOption = this.options.find(option => option.id === id);
    if (selectedOption) {
      this.inputValue = selectedOption.name;
    }
  }
}
