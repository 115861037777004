<div class="overlay">
</div>

<div class="centrado flex flex-col items-center justify-center">
    <img src="assets/svg/logo-blanco.svg" class="logo" alt="Logo Fincomercio" style="width: 120px; height: 120px;">
    <br>
    <span class="text-xs">{{ error }}</span>
    <br>
    <a class="text-white text-xs flex flex-col justify-center items-center" [href]="url" target="_blank">
        Si deseas contactar a tu asesor, haz clic aquí <img class="h-[50px] w-[50px] mt-3" src="assets/whatsapp.png" alt="whatsapp" >
    </a>
    
</div>