import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ListService } from '../../services/list.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit, OnChanges {

  locations: any[] = [];
  filteredLocations: any[] = [];
  form: FormGroup;
  isValid = false;
  @Input() disabled = false;
  @Input() resetTimer = false;
  @Input() defaultValue: any = null;
  @Input() label = '¿Dónde vives?';
  @Input() timerMs = 5000;
  @Output() valueState = new EventEmitter<number | null>();
  @ViewChild('location') location!: ElementRef;
  timer: any;
  isTimeout = false;
  filterTimeout: any;
  selectedLocation: any = null;  // Para almacenar el objeto seleccionado

  isLoading = false;

  constructor(private listService: ListService) {
    this.form = new FormGroup({
      location: new FormControl('', [Validators.required]),
    });
   }

  ngOnInit(): void {
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, this.timerMs);
    this.listService.getUbication("")
    .subscribe({
      next: (response: any) => {
        this.locations = response.ubications;
        this.findLocationById(this.defaultValue)
    },});
    if(this.disabled) {
      this.form.get('location')!.disable();
    }
  }

  findLocationById(id: any) {
    let ubications:any;
    this.listService.getUbicacion().subscribe({
      next: (response: any) => {
        ubications = response.list
        const ubication = ubications.find(ubic => ubic.id === id);
        this.setLocationDefaultValue(ubication.name)
      }
    })
  }

  ngOnChanges() {
    if(this.resetTimer) {
      clearTimeout(this.timer);
      this.isTimeout = false;
      this.timer = setTimeout(() => {
        this.isTimeout = true;
      }, 5000);
    }
    if(this.defaultValue) {
      this.setLocationDefaultValue(this.defaultValue);
    }
    if(this.disabled) {
      this.form.get('location')!.disable();
    }
  }

  setLocationDefaultValue(defaultValue) {
    this.form.get('location')!.setValue(defaultValue);
    if(this.defaultValue) {
      this.isValid = true;
    }
    const locationObj = this.locations.filter(location => location.id == defaultValue)[0];
    if(locationObj){
      this.location.nativeElement.value = locationObj.name;
    }
  }

  filterLocations(event: any) {
    this.isValid = false;
    const query = event.target.value.toLowerCase();
    if (this.filterTimeout) {
        clearTimeout(this.filterTimeout);
    }
    this.isLoading = true;
    this.filterTimeout = setTimeout(() => {
        this.listService.getUbication(query)
            .subscribe({
                next: (response: any) => {
                    this.locations = response.ubications;
                    this.filteredLocations = this.locations.filter(location => {
                        let wordsArray = query.split(' ');
                        let locationLower = location.name.toLowerCase();
                        for (let word of wordsArray) {
                            if (!locationLower.includes(word)) {
                                return false; // Si alguna palabra no está en el nombre de la ubicación, no la incluye
                            }
                        }
                        return true; // Si todas las palabras están en el nombre de la ubicación, la incluye
                    });
                    this.isLoading = false;
                },
                error: () => {
                    this.isLoading = false;
                }
            });
    }, 350);
}

  
  selectLocation(idLocation: number, location: string) {
    this.form.get('location')!.setValue(location);
    this.isValid = true;
    this.filteredLocations = [];
    this.valueState.emit(idLocation);
  }

  esc() {
    this.filteredLocations = [];
    this.form.get('location')!.setValue(null);
    this.isValid = false;
    this.valueState.emit(null);
  }

  makeInvalid() {
    this.isValid = false;
    this.valueState.emit(null);
  }

  validateBlur() {
    setTimeout( () => {
      if(!this.isValid) {
        this.esc();
      }
    }, 1000);
  }

}
