<app-header
[backRoute]="backRoute"
></app-header>
  <div class="h-[90vh] flex flex-col items-center justify-start text-center bg-gray-200">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center my-4 text-blue font-extrabold text-2xl">
            ¡Sigue avanzando!
        </div>
      </div>
  
      <div class="row">
        <div class="col text-start">
                    <form>
                      <app-field-number [minValidValue]="0" [defaultValue]="form.get('incomeExtraActivity')!.value" fieldLabel="Otros Ingresos" (fieldValue)="setIncomeExtraActivity($event)"></app-field-number>
                      <app-field-number [minValidValue]="0" [defaultValue]="form.get('outcome')!.value" fieldLabel="Egresos" (fieldValue)="setOutcome($event)"></app-field-number>
                      <app-field-number [minValidValue]="400000" [defaultValue]="form.get('assets')!.value" fieldLabel="Activos" (fieldValue)="setAssets($event)"></app-field-number>
                      <app-field-number [minValidValue]="100000" [defaultValue]="form.get('liabilities')!.value" fieldLabel="Pasivos" (fieldValue)="setLiabilities($event)"></app-field-number>
                    </form>
        </div>
      </div>
  
      <div class="button-next">
        <button
          type="button"
          [disabled]="form.invalid"
          class="btn btn-primary btn-custom"
          (click)="saveData()"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
  <!-- <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow> -->
 <app-button-help></app-button-help>
