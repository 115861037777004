<hr>
<div class="text-base leading-tight font-light mb-2 text-center">
    Si aún no estás desde tu teléfono, por favor escanea el QR con la cámara de tu celular.
    <br>
    <img class="mx-auto mt-2 p-4" [src]="consultor?.qrImage" crossorigin="anonymous" alt="Código consultor">
    <br>
    <span (click)="share()">Asesor: {{ consultor?.name | titlecase }}</span><br>
    <span (click)="share()">OnBoarding Digital Gamificada</span><br>
    <span (click)="share()">{{ getFullUrl() }}</span><br>
    <img (click)="share()" class="w-7 h-7 mx-auto mt-2" src="assets/share.png"><br>
</div>
