import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import anime from 'animejs';
import * as confetti from 'canvas-confetti';
import { SoundService } from '../services/sound-service.service';

@Component({
  selector: 'app-wow',
  templateUrl: './wow.component.html',
  styleUrls: ['./wow.component.scss']
})
export class WowComponent implements OnInit, OnChanges {

  @Input() progress: number = 0; 
  displayProgress: number = 0; 
  animate: boolean = false; 

  @Output() completed = new EventEmitter<boolean>();
  playSound: boolean = true;

  points = 0;
  winPoints = 500;
  private clappingAudio: HTMLAudioElement | null = null;

  constructor(private soundService: SoundService) {}

  async ngOnInit() {
    this.soundService.sound$.subscribe(isSoundEnabled => {
      this.playSound = isSoundEnabled;
      this.initializeAudio();

      this.progress = Math.max(1, Math.min(100, this.progress));

      this.startAnimation();
    });
  

    setTimeout(async() => {
      this.fireConfetti();
      document.getElementById('prize')!.style.display = 'block';
      document.getElementById('bg-white')!.style.display = 'block';
      document.getElementById('overlay')!.style.display = 'block';


      let i = 0;
      let timerMs = 300;
      const coins$ = setInterval( () => {
        if(i >= 2000) {
          clearInterval(coins$);
          return;
        }
        i+=timerMs;
      }, timerMs);
      let j = 0;
      let timerPointsMs = 50;
      const points$ = setInterval( () => {
        if(j >= 2000) {
          this.points = this.winPoints;
          clearInterval(points$);
          return;
        }
        this.points+= Math.round(this.winPoints / ((2000 + timerPointsMs) / timerPointsMs));
        j+=timerPointsMs;
      }, timerPointsMs);
  


      anime({
        targets: document.getElementById('prize'),
        translateY: ['-100%', '0%'],
        easing: 'easeOutElastic(1.5, .4)',
        duration: 1000,
        delay: anime.stagger(100)
      });
      anime({
        targets: document.getElementById('bg-white'),
        translateY: ['200%', '0%'],
        easing: 'easeOutElastic(1.5, .4)',
        duration: 1000,
        delay: anime.stagger(100)
      });
      anime({
        targets: '.overlay',
        backgroundColor: ['rgba(00, 58, 162, 0)', 'rgba(00, 58, 162, 1)'],
        duration: 500,  // Duración de la animación en milisegundos
        easing: 'linear' // Tipo de transición
      });
  
    }, 50);

  }

  startAnimation(): void {
    this.animate = true;

    const interval = setInterval(() => {
        this.displayProgress++;
        if (this.displayProgress >= this.progress) {
            clearInterval(interval); 
        }
    }, 20); 
}

  ngOnChanges(changes: SimpleChanges) {

    if (changes.playSound) {
      this.initializeAudio();
    }
  }

  private initializeAudio() {
    if (this.playSound) {
      if (!this.clappingAudio) {
        this.clappingAudio = new Audio("assets/clappings.mp3");
        this.clappingAudio.load();
      }
      this.clappingAudio.play();
    } else {
      if (this.clappingAudio) {
        this.clappingAudio.pause();
        this.clappingAudio.currentTime = 0;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateCanvasSize();
  }
  count = 100;
  defaults = {
    origin: { y: 0.8 },
  };

  fire(particleRatio: any, opts: any) {
    // @ts-ignore
    const confettiInstance = confetti.create(document.getElementById('canvas'), {
      resize: true,
      useWorker: true,
    });
    
    const options = Object.assign({}, this.defaults, opts, {
      particleCount: Math.floor(this.count * particleRatio),
    });

    confettiInstance(options);
    }
  
  async fireConfetti() {
    await this.wait(500);
    this.fire(1, {
      spread: 90,
      startVelocity: 75,
      //decay: 0.1,
      scalar: 1,
    });
    await this.wait(1200);
    this.fire(0.6, {
      spread: 70,
      startVelocity: 85,
      //decay: 0.7,
      scalar: 0.8,
    });
    await this.wait(900);
    this.fire(1.5, {
      spread: 85,
      startVelocity: 80,
      //decay: 0.2,
      scalar: 0.9,
    });

    // this.fire(0.25, {
    //   spread: 26,
    //   startVelocity: 55,
    // });
  
    // this.fire(0.2, {
    //   spread: 60,
    // });
  
    this.fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
  
    // this.fire(0.1, {
    //   spread: 120,
    //   startVelocity: 25,
    //   decay: 0.92,
    //   scalar: 1.2,
    // });
  
    // this.fire(0.1, {
    //   spread: 120,
    //   startVelocity: 45,
    // });
  }

  updateCanvasSize() {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  }  
  
  wait(time: number) {
    return new Promise( resolve => {
      setTimeout( () => {
        resolve(true);
      }, time)
    }); 
  }

  finish() {
    this.completed.emit(true);
  }

  toggleSound(isSoundEnabled: boolean) {
    this.playSound = isSoundEnabled;
    this.initializeAudio();
  }  
}
