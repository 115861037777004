<app-header
[backRoute]="backRoute"
[showBack]="false"
></app-header>
  <div class="h-[90vh] flex flex-col items-center pt-[60px] text-center bg-light-gray" #appContainer>
    <div class="container">
      <div class="row">
        <div class=" flex justify-center items-center">
          <img src="assets/svg/Campana.svg" class="logo-bienvenido" alt="Logo Bienvenido" />
        </div>
          <h1 class="my-4 text-dark-blue font-bold text-2xl">Paga tu ahorro</h1>
      </div>
      <div class="row">
        <div class="flex flex-col text-xs font-thin text-black text-start">
            <div class="flex px-3 pt-2">1. <div class="pr-l">Ve a la oficina Fincomercio más cercana. <br></div></div>
            <div class="flex px-3 pt-2">2. <div class="pr-l">Con tu número de cédula podrás realizar el pago a uno de nuestros asesores en caja        </div></div>
        </div>
      </div>
      <div class="row p-3">
        <div class="offset-md-6 col-md-5 text-end">
            <div class="row mt-2 p-2 bg-light-blue-100 rounded-md">
                <div class="bg-light-blue h-auto w-full flex items-center text-xs" style="border-radius: 6px;">
                  <p class="m-0 w-full text-start text-xs py-3 px-4"><b>Recuerda:</b> <br>
                    Tienes hasta <b>{{stringDate}}</b> para pagar la primera cuota.
                  </p>
                </div>
              </div>
          <div class="row">
            <button type="button" (click)="next()" class="btn-custom mt-2">Consultar oficinas Fincomercio</button>
            <button type="button" (click)="finish()" class="h-10 w-full bg-white text-dark-blue  mt-2" style="    transition: background-color 0.3s ease;     border-radius: 6px;     border: 1px solid #003085;">Finalizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  