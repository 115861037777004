<div class="h-screen w-screen flex items-center justify-center" *ngIf="continueOTP"></div>
<div class="fixed bg-white p-[20px] w-4/5 h-auto max-h-[80%] overflow-auto flex flex-col items-center justify-center" style="top: 50%; left: 50%; z-index: 1000; box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); border-radius: 10px; transform: translate(-50%, -50%);" #modal>
  <h2 class="text-center text-[#003085] text-[20px]"><b> Continua con tu vinculacion...</b></h2>
    <p class="text-center text-[16px] mt-4">Hemos detectado que en los ultimos 7 dias has intentado vincularte.</p>
    <form [formGroup]="form">
        <div class="mb-3">
            <div class="bg-gray-200 p-2 rounded-md inline-block my-2">
                <label for="address" class="form-label text-[#555555] text-sm">
                  Hemos enviado a tu número celular ({{this.startPhone}}*****{{this.endPhone}}) registrado un código de seis (6) dígitos, ingrésalo aquí para continuar con tu vinculación:
                </label>
            </div> 
            <input formControlName="code" type="text" class="form-control" id="address" aria-describedby="addressHelp">
        </div>  
      </form>
      
    <div class="flex flex-col w-full">
    <div>Podrá reenviar el código en {{ seconds }} segundos...</div>
      <input type="button" class="btn btn-primary btn-custom h-[2.5rem] w-full my-2" value="Reenviar código" (click)="resendCode()" [disabled]="seconds > 0">
      <button
            type="button"
            class="btn btn-primary btn-custom"
            (click)="checkCode()"
            [disabled]="form.get('code')?.invalid"
        >
            Verificar
        </button>
    </div>
</div>
