<div class="flex px-3 items-center justify-between h-[10vh] p-0 body-blue">
  <div class="text-white cursor-pointer" (click)="back()">Regresar</div>
  <div class="flex items-center justify-end h-full pr-2">
    <span class="text-white font-bold text-sm text-center mr-2">
      Llevas acumulados <br> 2.000 puntos
    </span>
    <img src="assets/svg/logo-blanco.svg" class="h-[70px]" alt="Logo Fincomercio" />
  </div>
</div>

<div class="flex flex-col items-center justify-center text-center h-[90vh] bg-light-gray">
  <div class="container">
    <div class="row">
      <div class="col d-flex justify-content-center text-blue font-bold text-2xl mb-4">
        ¡FELICITACIONES!
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center text-blue text-lg font-light mb-4">
        Ya haces parte de esta gran familia
      </div>
    </div>

    <div class="row">
      <div class="offset-md-6 col-md-5 text-end">
        <div class="row">
          <button type="button" class="btn btn-primary mt-2" (click)="wow()">Gana más puntos! Completa tu información</button>
          <button type="button" class="btn btn-primary mt-2">Conoce la oferta que tenemos para ti</button>
          <a type="button" class="btn btn-primary mt-2" target="_blank" [href]="'https://api.whatsapp.com/send?phone=+57' + phone + '&text=Hola,%20estoy%20interesado%20en%20obtener%20más%20información.'">Comunícate con tu asesor</a>
          <a type="button" class="btn btn-primary mt-2" href="https://www.fincomercio.com/">Finalizar</a>
        </div>
      </div>
    </div>

    <div>
      <button type="button" class="btn btn-primary mt-8" (click)="wow()">Continuar</button>
    </div>
  </div>
</div>
<app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>
