<div class="button-group">
  <label *ngFor="let method of paymentMethods" [for]="'paymentMethod' + method.id">
    <input type="radio" style="" name="paymentOptions" [id]="'paymentMethod' + method.id" [value]="method.id" (change)="selectPaymentMethod(method.id)">
    <div class="button-payment bg-white h-10 w-full text-gray-500 flex items-center text-base mb-4" style="transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease; text-decoration: none; border: 1px solid #777676; border-radius: 6px; ">
      <div class="radio" [class.checked]="method.id === formControl.value"></div>
      <div class="w-[1px] h-full bg-[#777676] mx-4"></div>
      <div class="flex items-center">{{ method.name }}</div>
    </div>
  </label>
</div>
