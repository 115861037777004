import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SecurityService } from '../services/security.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-continue',
  templateUrl: './modal-continue.component.html',
  styleUrls: ['./modal-continue.component.scss']
})
export class ModalContinueComponent {

  @Input() continueOTP = false;
  @Input() backRoute = '/app/finish';
  @Input() nextRoute = '/app/pap';
  @Output() modalState = new EventEmitter<{state:boolean}>();
  
  //showHeaderText = false;
  newRoute = '';
  wowDisplay = false;
  isTimeout = false;
  showTermsFlag = false
  hasValidationPerson = false;
  disableModal: boolean;

  form: FormGroup;
  loading = false;
  code = '';
  timer: any;
  seconds = 10;
  isOtpValidated = false;

  startPhone = '';
  endPhone = '';
  text = '';

  papData: any = {};

  constructor(private router: Router,
    private securityService: SecurityService,
    private title: Title,) {
    this.title.setTitle('Onboarding Digital');
    this.form = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(6)])
    });

    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 20000);
  }

  async ngOnInit() {
    const route = localStorage.getItem('route'); 
    const currentUrl = this.router.url;
    if (route && currentUrl !== route) {
       await this.router.navigateByUrl(route);
    }
    this.getContinueOTP();
  }

  getContinueOTP() {
    this.securityService.sendContinueOTP().subscribe({
      next: (response: any) => {
        if(response.isOtpValidated) {
          this.isOtpValidated = true;
        } else {
          this.seconds = 120;
          this.timer = setInterval(() => {
            this.seconds--;
            if(this.seconds === 0) {
              clearInterval(this.timer);
            }
          }, 1000);
          this.startPhone = response.startPhone;
          this.endPhone = response.lastPhone;
        }
      }  
    });
  }

  checkCode() {
    this.loading = true;
    if(this.form.valid || this.isOtpValidated) {
      this.securityService.checkContinueOTP(this.form.value.code).subscribe({
        next: async (response: any) => {
          this.loading = false;
          if(!response.isOtpValidated) {
            await Swal.fire({
              title: 'Código de verificación',
              text: `Se ha confirmado el código de verificación`,
              icon: 'success',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#0096d2',
            }).then((result) => {
              if (result.isConfirmed) {
                this.loading = true;
                this.modalChanges(true);
              }
            });
          } else {
            this.loading = false;
            this.modalChanges(true);
          }
        },
        error: (error: any) => {
          this.loading = false;
          console.error(error);
          Swal.fire({
            title: 'Código de verificación',
            text: `El código de verificación es incorrecto`,
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#0096d2',
          })
        },
      });
    }
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
    this.disableModal;

  }

  modalChanges(event:boolean){
    this.modalState.emit({state : event});
  }

  resendCode() {
    this.getContinueOTP();
  }
}
