import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SecurityService } from '../services/security.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalsComponent implements OnInit {
  @ViewChild('appContainer', { static: true }) appContainer: ElementRef;

  @Input() imagePath: string = '';
  @Input() targetText: number;
  @Input() h2Text: string;
  @Input() h2subTitle: string;
  @Input() quotaText!: number;
  @Input() durationText!: number;
  @Input() methodText!: number; 
  @Input() noteText!: string;
  @Input() buttonNextText!: string
  @Input() buttonBackText!: string;
  @Input() showTermsC: boolean = true;
  @Input() textHeader: string = '';
  @Input() subTextHeader: string = '';
  @Input() modalFinishText: boolean = false;
  @Input() disableButton = false;
  @Input() showHeaderText:boolean;
  @Input() authorization :boolean;
  @Input() showInfo = false
  @Input() showModal = false
  @Input() showBackButton = true
  @Input() backRoute = '/app/finish';
  @Input() nextRoute = '/app/pap';
  @Input() nombreDelPAP = 'EjemploPAP';
  @Output() modalState = new EventEmitter<{state:boolean}>();
  
  //showHeaderText = false;
  newRoute = '';
  wowDisplay = false;
  isTimeout = false;
  showTermsFlag = false
  hasValidationPerson = false;
  disableModal: boolean;

  form: FormGroup;
  isLoading = false;
  timer;

  papData: any = {};

  constructor(private router: Router,
    private securityService: SecurityService,
    private title: Title,) {
    this.title.setTitle('Onboarding Digital | PAP');
    this.form = new FormGroup({
      authorization: new FormControl(true , Validators.compose([
        Validators.requiredTrue
      ])),
    });

    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 20000);
  }

  async ngOnInit() {
    const route = localStorage.getItem('route'); 
    const currentUrl = this.router.url;
    if (route && currentUrl !== route) {
       await this.router.navigateByUrl(route);
    }
  }

  formatQuota(): string {
    this.quotaText.toString()

    return this.quotaText.toLocaleString('es-ES'); 
  }


  async next() {
      localStorage.setItem('route', this.nextRoute);
      this.router.navigateByUrl(this.nextRoute);
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
    this.disableModal;

    }
  showTerms() {
    this.showTermsFlag = true;
  }

  modalChanges(event:boolean){
    this.modalState.emit({state : event});
  }
}
