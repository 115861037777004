import { Component } from '@angular/core';
import { SoundService } from 'src/app/modules/vinculaciones/services/sound-service.service';

@Component({
  selector: 'app-buton-sound',
  templateUrl: './buton-sound.component.html',
  styleUrls: ['./buton-sound.component.scss']
})
export class ButonSoundComponent {
  soundEnabled$;

  constructor(private soundService: SoundService) {
    this.soundEnabled$ = this.soundService.sound$;
  }

  toggleSound() {
    this.soundService.toggleSound();
  }
}
