import { Component } from '@angular/core';

@Component({
  selector: 'app-error-ip',
  standalone: true,
  imports: [],
  templateUrl: './error-ip.component.html',
  styleUrl: './error-ip.component.scss'
})
export class IpErrorComponent {

}
