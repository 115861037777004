import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ListService } from '../services/list.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecurityService } from '../services/security.service';
import { ContributionsService } from '../services/contributions.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @ViewChild('appContainer', { static: true }) appContainer!: ElementRef;

  backRoute = '/app/basic';
  nextRoute = '/app/balance';
  wowDisplay = false;
  form: FormGroup;
  quota: number;
  showLoader = true
  isValidAddress = false;
  conditionsDebit:boolean;

  isTimeout = false;
  timer: any;
  resetTimer = false;

  dataDebit = {}
  hasAutomaticDebit = false;

  allValid = false;
  dataUser: any = {};

  asotips: any[] = [];
  locations: any[] = [];

  addressData: any = {};

  modalContinue = false;

  constructor(private router: Router,
              private title: Title,
              private listService: ListService,
              private securityService: SecurityService,
              private _contributionService : ContributionsService
    ) {
      this.title.setTitle('Onboarding Digital | Direcciones');
      this.form = new FormGroup({
        asotip: new FormControl('', [Validators.required]),
        location: new FormControl('', [Validators.required]),
        address: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(55)]),
        neighborhood: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]),
        automaticDebit: new FormControl(false, [Validators.required]),
      });
      this.timer = setTimeout(() => {
        this.isTimeout = true;
      }, 5000);
    }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(currentUrl);
    } else {
      this.listService.getAsotip()
        .subscribe({
          next: (response: any) => {
            this.asotips = response.list;
        },});
      this.securityService.getAddressData()
        .subscribe({
          next: (response: any) => {
            this.addressData = response.addressData;
            this.form.get('asotip')!.setValue(response.addressData.asotip);
            this.form.get('location')!.setValue(response.addressData.location);
            this.form.get('address')!.setValue(response.addressData.address);
            this.form.get('neighborhood')!.setValue(response.addressData.neighborhood);
            this.form.get('automaticDebit')!.setValue(response.addressData.automaticDebit);
            this.preChargeData(this.addressData);

            this.dataDebit = {
              debitType : response.addressData.debitType,
              bank : response.addressData.bank,
              accountNumber : response.addressData.accountNumber,
              accountType : response.addressData.accountType,
              debitDay : response.addressData.debitDay,
            }
          }
        });
      this.verifyContinueOtp();
    }
  }

  ngAfterViewInit() {
  }

  onFormValueChange(value: any): void {
    this.dataUser = {
      ...this.dataUser,
      ...value
    }
    this.validateAllValid();
  }

  verifyContinueOtp() {
    this.securityService.verifyContinueOTP()
      .subscribe({
        next: (response: any) => {
          if(response.needContinueOtp == true) {
            this.showModal();
          }
        },
      });
  }

  closeModal(){
    this.isValidAddress = false;
  }

  changeOption() {
    clearTimeout(this.timer);
    this.resetTimer = true;
    setTimeout(() => {
      this.resetTimer = false;
    }, 1);
    this.isTimeout = false;
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 5000);
    this.allValid = false;
    switch(this.form.get('asotip')!.value) {
      case '1':
        this.dataUser = {
          asotip: this.form.get('asotip')!.value,
          location: this.form.get('location')!.value,
          address: this.form.get('address')!.value,
          neighborhood: this.form.get('neighborhood')!.value,
          automaticDebit: this.form.get('automaticDebit')!.value,
          indact: null,
          income: null,
          codpro: null,
        };
        break;
      case '2':
        this.dataUser = {
          asotip: this.form.get('asotip')!.value,
          location: this.form.get('location')!.value,
          address: this.form.get('address')!.value,
          neighborhood: this.form.get('neighborhood')!.value,
          automaticDebit: this.form.get('automaticDebit')!.setValue(false),
          since: null,
          code: null,
          codpro: null,
          company: null,
          caract: null,
          income: null,
        };
        break;
      case '3':
        this.dataUser = {
          asotip: this.form.get('asotip')!.value,
          location: this.form.get('location')!.value,
          address: this.form.get('address')!.value,
          neighborhood: this.form.get('neighborhood')!.value,
          automaticDebit: this.form.get('automaticDebit')!.setValue(false),
          fonpen: null,
          resolution: null,
          since: null,
          codpro: null,
          income: null,
        };
        break;
      case '4':
        this.dataUser = {
          asotip: this.form.get('asotip')!.value,
          location: this.form.get('location')!.value,
          address: this.form.get('address')!.value,
          neighborhood: this.form.get('neighborhood')!.value,
          automaticDebit: this.form.get('automaticDebit')!.setValue(false),
          income: null,
        };
        break;
      case '5':
        this.dataUser = {
          asotip: this.form.get('asotip')!.value,
          location: this.form.get('location')!.value,
          address: this.form.get('address')!.value,
          neighborhood: this.form.get('neighborhood')!.value,
          automaticDebit: this.form.get('automaticDebit')!.setValue(false),
          indact: null,
          income: null,
          codpro: null,
        };
        break;
      default:
        break;
    }
  }

  preChargeData(addressData: any) {
    clearTimeout(this.timer);
    this.resetTimer = true;
    setTimeout(() => {
      this.resetTimer = false;
    }, 1);
    this.isTimeout = false;
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 5000);
    this.allValid = false;
    switch(this.form.get('asotip')!.value) {
      case 1:
        this.dataUser = {
          asotip: this.form.get('asotip')!.value,
          location: this.form.get('location')!.value,
          address: this.form.get('address')!.value,
          neighborhood: this.form.get('neighborhood')!.value,
          automaticDebit: this.form.get('automaticDebit')!.value,
          indact: null,
          income: null,
          codpro: null,
        };
        break;
      case 2:
        this.dataUser = {
          asotip: this.form.get('asotip')!.value,
          location: this.form.get('location')!.value,
          address: this.form.get('address')!.value,
          neighborhood: this.form.get('neighborhood')!.value,
          since: null,
          code: null,
          codpro: null,
          company: null,
          caract: null,
          income: null,
        };
        break;
      case 3:
        this.dataUser = {
          asotip: this.form.get('asotip')!.value,
          location: this.form.get('location')!.value,
          address: this.form.get('address')!.value,
          neighborhood: this.form.get('neighborhood')!.value,
          fonpen: null,
          resolution: null,
          since: null,
          codpro: null,
          income: null,
        };
        break;
      case 4:
        this.dataUser = {
          asotip: this.form.get('asotip')!.value,
          location: this.form.get('location')!.value,
          address: this.form.get('address')!.value,
          neighborhood: this.form.get('neighborhood')!.value,
          income: null,
        };
        break;
      case 5:
        this.dataUser = {
          asotip: this.form.get('asotip')!.value,
          location: this.form.get('location')!.value,
          address: this.form.get('address')!.value,
          neighborhood: this.form.get('neighborhood')!.value,
          indact: null,
          income: null,
          codpro: null,
        };
        break;
      default:
        break;
    }
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    this.router.navigateByUrl(this.nextRoute);
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  wow() {
    this.securityService.setAddressData(this.dataUser)
      .subscribe({
        next: (response: any) => {
      },});
    this.wowDisplay = true;
  }
  
  setLocation(location) {
    this.form.get('location')!.setValue(location);
    this.dataUser!.location = location;
    this.validateAllValid();
  }

  setAddress() {
    this.dataUser!.address = this.form.get('address')!.value;
    this.validateAllValid();
  }

  setAutomaticDebit() {
    const automaticDebitValue = this.form.get('automaticDebit')!.value;
  
    this.dataUser.automaticDebit = automaticDebitValue;
  
    if (automaticDebitValue) {
      this.dataUser = {
        ...this.dataUser,
        debitType: null,
        bank: null,
        accountNumber: null,
        accountType: null,
        debitDay: null,
      };
    } else {
      const propertiesToRemove = ['debitType', 'bank', 'accountNumber', 'accountType', 'debitDay'];
      propertiesToRemove.forEach((prop) => this.removeProperty(prop));
    }
    this.validateAllValid();
  }
  
  
  
  removeProperty(property: string) {
    const { [property]: _, ...rest } = this.dataUser;
    this.dataUser = rest;
  }

  setNeighborhood() {
    this.dataUser!.neighborhood = this.form.get('neighborhood')!.value;
    this.validateAllValid();
  }


  validateAllValid() {
    let isAllValid = true;
    for (const key in this.dataUser) {
      if (Object.prototype.hasOwnProperty.call(this.dataUser, key)) {
        const element = this.dataUser[key];
        if(key === 'resolution' || key === 'code') {
          continue;
        }
        if(element === null) {
          isAllValid = false;
        }
      }
    }
    this.allValid = isAllValid && this.form.valid;
  }

  setChildValues(formValues) {
    this.dataUser = {
      ...this.dataUser,
      ...formValues,
    };
    this.validateAllValid();
  }

  checkAddress() {
    this.securityService.checkAddress(this.dataUser.address)
      .subscribe({
        next: (response: any) => {
          this.saveData();
        },
        error: (err: any) => {
          this.isValidAddress = true;
        }
      });
  }

  saveData() {
    localStorage.setItem('income', this.dataUser.income)
    this.securityService.setAddressData(this.dataUser)
      .subscribe({
        next: (response: any) => {
          this.wow();
      },
      error: (err: any) => {
        this.isValidAddress = true;
      }
    });
  }
       
  setModal($event: any) {
    if ($event.state == true) {
      this.hideModal();
    }
  }

  showModal() {
    this.modalContinue = true;
    this.appContainer.nativeElement.classList.add('blur-sm', 'pointer-events-none');
  }

  hideModal() {
    this.modalContinue = false;
    this.appContainer.nativeElement.classList.remove('blur-sm', 'pointer-events-none')
  }

}