<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Selector para Tipo de Débito -->
    <div class="mb-3">
      <label for="debitType" class="form-label text-blue">Seleccione el tipo de débito:</label>
      <select
        formControlName="debitType"
        class="form-select"
        id="debitType"
        [ngClass]="{
          'is-invalid': form.get('debitType')?.invalid && (isTimeout || form.get('debitType')?.touched)
        }"
      >
        <option value="" disabled selected>Seleccione una opción</option>
        <option value="INTERNO">Débito Interno</option>
        <option value="EXTERNO">Débito Externo</option>
      </select>
      <div *ngIf="form.get('debitType')?.invalid && (isTimeout || form.get('debitType')?.touched)" class="text-custom-red">
        Por favor seleccione un tipo de débito.
      </div>
    </div>
  
    <!-- Campos adicionales para Débito Externo -->
    <div *ngIf="form.get('debitType')?.value === 'EXTERNO'">
      <!-- Selector para Banco a Debitar -->
      <div class="mb-3">
        <label for="bank" class="form-label text-blue">Banco a Debitar</label>
        <select
          formControlName="bank"
          class="form-select"
          id="bank"
          [ngClass]="{
            'is-invalid': form.get('bank')?.invalid && (isTimeout || form.get('bank')?.touched)
          }"
        >
          <option value="" disabled selected>Seleccione un banco</option>
          <option *ngFor="let bank of bankOptions" [value]="bank.bankName">{{ bank.bankName }}</option>
        </select>
        <div *ngIf="form.get('bank')?.invalid && (isTimeout || form.get('bank')?.touched)" class="text-custom-red">
          Por favor seleccione un banco.
        </div>
      </div>
  
      <!-- Número de Cuenta -->
      <div class="mb-3">
        <label for="accountNumber" class="form-label text-blue">Número de Cuenta</label>
        <input
          formControlName="accountNumber"
          type="text"
          class="form-control"
          id="accountNumber"
          [ngClass]="{
            'is-invalid': form.get('accountNumber')?.invalid && (isTimeout || form.get('accountNumber')?.touched)
          }"
        />
        <div *ngIf="form.get('accountNumber')?.invalid && (isTimeout || form.get('accountNumber')?.touched)" class="text-custom-red">
          Por favor ingrese un número de cuenta válido.
        </div>
      </div>
  
      <!-- Tipo de Cuenta -->
      <div class="mb-3">
        <label for="accountType" class="form-label text-blue">Tipo de Cuenta</label>
        <select
          formControlName="accountType"
          class="form-select"
          id="accountType"
          [ngClass]="{
            'is-invalid': form.get('accountType')?.invalid && (isTimeout || form.get('accountType')?.touched)
          }"
        >
          <option value="" disabled selected>Seleccione un tipo</option>
          <option value="Ahorros">Ahorros</option>
          <option value="Corriente">Corriente</option>
        </select>
        <div *ngIf="form.get('accountType')?.invalid && (isTimeout || form.get('accountType')?.touched)" class="text-custom-red">
          Por favor seleccione un tipo de cuenta.
        </div>
      </div>
  
      <!-- Día Débito -->
      <div class="mb-3">
        <label for="debitDay" class="form-label text-blue">Día Débito</label>
        <input
          formControlName="debitDay"
          type="number"
          class="form-control"
          id="debitDay"
          [ngClass]="{
            'is-invalid': form.get('debitDay')?.invalid && (isTimeout || form.get('debitDay')?.touched)
          }"
        />
        <div *ngIf="form.get('debitDay')?.invalid && (isTimeout || form.get('debitDay')?.touched)" class="text-custom-red">
          Por favor ingrese un día entre 1 y 31.
        </div>
      </div>
    </div>
  </form>
  