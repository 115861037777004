<form [formGroup]="form">
<app-companies [defaultValue]="defaultValue.company" (valueState)="setCompany($event)" ></app-companies>
<div class="mb-3">
    <label for="since" class="form-label text-blue">¿Desde cuando trabajas en esa empresa?</label>
    <input type="date" formControlName="since" class="form-control" id="since" aria-describedby="sinceHelp"
        (keyup)="setDate()" (change)="setDate()"
        [ngClass]="{'is-invalid': form.get('since')?.invalid && (isTimeout || form.get('since')?.touched)}" >
    <div *ngIf="form.get('since')?.invalid && (isTimeout || form.get('since')?.touched)" class="text-custom-red">Por favor selecciona una fecha válida</div>
    <div *ngIf="(form.controls.since.errors?.pastDate || form.get('since')?.invalid) && (isTimeout || form.get('since')?.touched)"  class="text-custom-red">
        La fecha debe ser menor que la fecha actual.
    </div>
</div>  
<div class="mb-3">
    <label for="code" class="form-label text-blue">Si tienes código de empleado por favor digitalo</label>
    <input type="text" formControlName="code" class="form-control" id="code" aria-describedby="codeHelp" (keyup)="setCode()">
</div>  
<app-caract [defaultValue]="defaultValue.caract" (valueState)="setCarac($event)"></app-caract>
<app-field-number [defaultValue]="defaultValue.income" [minValidValue]="400000"  (fieldValue)="setIncome($event)" fieldLabel="¿Cuál es tu ingreso por la actividad económica principal?"></app-field-number>
<app-profession
    [defaultValue]="defaultValue.codpro"
    [options]="codpros"
    label="¿Cuál es tu profesión u oficio?"
    placeholder="Escribe tu profesión u oficio"
    (selectionChange)="onProfessionSelected($event)">
</app-profession>
</form>