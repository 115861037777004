<div class="fixed inset-0 bg-blue-800 bg-opacity-100" id="overlay">
  
</div>
<div class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-max">
  <div id="bg-white" class=" w-[10000px] h-[250px] bg-white mt-[-310px]"></div>
</div>

<div class="top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute w-screen">
  <div class="text-center">
    <span class="text-yellow-400 font-bold text-4xl">
      FELICITACIONES
    </span>
    <br>
    <br>
    <div class="flex justify-center items-center w-full">
      <img id="prize" class="w-full max-w-[500px] mt-[-3em] mb-[-5em]" src="../../../../assets/trofeo.png" alt="Descripción de la imagen">

    </div>
    <br>
    <div class="text-white font-bold text-2xl leading-tight">
      <div class="text-center overflow-hidden">
        <span class="relative inline-block px-2.5">
          ¡CONTINÚA <br> AVANZANDO!
          <span class="absolute top-1/2 right-full border-b-2 border-white mr-3"></span>
          <span class="absolute top-1/2 left-full border-b-2 border-white ml-3"></span>
        </span>
      </div>
    </div>
    <button class="btn btn-warning" (click)="finish()">Aceptar</button>

    <!-- <div class="my-4 w-full h-14 px-10 flex justify-center items-center">
      <div class="w-full bg-white rounded-full h-4 shadow-inner relative">
          <div
              class="h-4 bg-gradient-to-r from-blue-500 to-blue-700 rounded-full shadow-lg"
              [ngClass]="{ 'animate-progress': animate }"
              [ngStyle]="{ '--progress': progress + '%' }"
          ></div>
      </div>
      <span class="ml-4 text-white font-bold">{{ displayProgress }}%</span>
  </div> -->

    <app-buton-sound class="flex justify-center pt-4 w-full"></app-buton-sound>
  </div>
</div>

