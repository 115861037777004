<div class="overlay">
</div>

<div class="centrado">
    Oops!  Parece que algo no está bien, <br>
    por favor revisa tu información
    <br>
    <span class="text-xs">{{ error }}</span>
    <br>
    <button class="btn btn-warning" (click)="retry()">Reintentar</button>
    <br>
    <a class="text-white text-xs flex items-center justify-center flex-col my-4" [href]="url" target="_blank">
        O contacta a tu asesor, clic aquí <img class="w-[50px] h-[50px]" src="assets/whatsapp.png" alt="whatsapp" >
    </a>
    
</div>