<form [formGroup]="form">
  <div class="mb-3">
    <label for="nationality" class="form-label text-blue">{{ label }}</label>
    <input type="text" class="form-control" #nationality id="nationality" formControlName="nationality" aria-describedby="nationalityHelp" 
          placeholder=""
          [ngClass]="{'is-invalid': !isValid && (isTimeout || form.get('nationality')?.touched)}" 
          (blur)="validateBlur()" 
          (keydown)="makeInvalid()" 
          (input)="filterNationalities($event)" 
          (click)="showAllNationalities()" 
          (keydown.escape)="esc()">

    <ul id="list" class="list-group mt-2 max-h-[300px] overflow-y-scroll relative" *ngIf="showDropdown && filteredNationalities.length > 0">
      <li class="list-group-item" *ngFor="let nationality of filteredNationalities" (click)="selectNationality(nationality.id, nationality.name)">{{ nationality.name }}</li>
    </ul>
    <div *ngIf="!isValid && (isTimeout || form.get('nationality')?.touched)" class="text-custom-red">Por favor selecciona un elemento de la lista.</div>
  </div>
</form>
