<form [formGroup]="form">
<div class="mb-3">
    <label for="indact" class="form-label text-blue">Cuéntanos más de tu actividad</label>
    <select formControlName="indact" class="form-select" id="indact" aria-describedby="indactHelp" (change)="changeOption()"
        [ngClass]="{'is-invalid': form.get('indact')?.invalid && (isTimeout || form.get('indact')?.touched)}" >
        <option value="" selected>Selecciona una opción...</option>
        <option *ngFor="let item of indacts" [value]="item.id">{{ item.name }}</option>
    </select>
    <div *ngIf="form.get('indact')?.invalid && (isTimeout || form.get('indact')?.touched)" class="text-custom-red">Por favor selecciona un elemento de la lista</div>
</div>
<app-field-number [defaultValue]="defaultValue.income" (fieldValue)="setIncome($event)" [minValidValue]="400000" fieldLabel="¿Cuál es tu ingreso por la actividad económica principal?"></app-field-number>
    <app-profession
        [defaultValue]="defaultValue.codpro"
        [options]="codpros"
        label="¿Cuál es tu profesión u oficio?"
        placeholder="Escribe tu profesión u oficio"
        (selectionChange)="onProfessionSelected($event)">
    </app-profession>
</form>