<div class="mb-3">
  <label for="autocomplete" class="form-label text-blue">{{ label }}</label>
  <input
    type="text"
    class="form-control"
    id="autocomplete"
    placeholder="{{ placeholder }}"
    (input)="filterOptions()"
    [(ngModel)]="inputValue"
    aria-describedby="autocompleteHelp"
  />
  
  <ul
    *ngIf="filteredOptions.length > 0 && inputValue"
    class="list-group mt-2"
    style="max-height: 300px; overflow-y: auto; width: 100%;"
  >
    <li
      *ngFor="let item of filteredOptions"
      class="list-group-item list-group-item-action"
      (click)="selectOption(item)"
    >
      {{ item.name }}
    </li>
  </ul>
</div>
