import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GreetingComponent } from './greeting/greeting.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services-component/services.component';
import { CardComponent } from './card/card.component';
import { VideosComponent } from './videos/videos.component';
import { BasicComponent } from './basic/basic.component';
import { AddressComponent } from './address/address.component';
import { JobComponent } from './job/job.component';
import { SignComponent } from './sign/sign.component';
import { CongratsComponent } from './congrats/congrats.component';
import { BalanceComponent } from './balance/balance.component';
import { ForeignComponent } from './foreign/foreign.component';
import { FinishComponent } from './finish/finish.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WowComponent } from './wow/wow.component';
import { FieldNumberComponent } from './field-number/field-number.component';
import { WaitingComponent } from './waiting/waiting.component';
import { ErrorComponent } from './error/error.component';
import { IndependientComponent } from './address/independient/independient.component';
import { AggrementComponent } from './address/aggrement/aggrement.component';
import { PensionerComponent } from './address/pensioner/pensioner.component';
import { StudientComponent } from './address/studient/studient.component';
import { LocationsComponent } from './address/locations/locations.component';
import { CompaniesComponent } from './address/companies/companies.component';
import { CaractComponent } from './address/caract/caract.component';
import { FonpenComponent } from './address/fonpen/fonpen.component';
import { SoyYoModule } from 'src/app/modules/soy-yo/soy-yo.module';
import { Congrats2Component } from './congrats2/congrats2.component';
import { QrComponent } from 'src/app/modules/vinculaciones/qr/qr.component';
import { ErrorProcesarOtroMedioComponent } from './error-procesar-otro-medio/error-procesar-otro-medio.component';
import { NationalityComponent } from './nationality/nationality.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { PapComponent } from './pap/pap.component';
import { TargetComponent } from './target/target.component';
import { FinishPapComponent } from './finish-pap/finish-pap.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { PapCashComponent } from './pap-cash/pap-cash.component';
import { ModalsComponent } from './modal/modal.component';
import { ModalContinueComponent } from './modal-continue/modal-continue.component';
import { ProfessionComponent } from './address/profession/profession.component';


const routes: Routes = [
  {
    path: '',
    // component: MainComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'app/services',
        component: ServicesComponent,
      },
      {
        path: 'app/video',
        component: VideosComponent,
      },
      {
        path: 'app/basic',
        component: BasicComponent,
      },
      {
        path: 'app/address',
        component: AddressComponent,
      },
      {
        path: 'app/job',
        component: JobComponent,
      },
      {
        path: 'app/sign',
        component: SignComponent,
      },
      {
        path: 'app/congrats',
        component: CongratsComponent,
      },
      {
        path: 'app/balance',
        component: BalanceComponent,
      },
      {
        path: 'app/foreign',
        component: ForeignComponent,
      },
      {
        path: 'app/foreignCheck',
        component: ErrorProcesarOtroMedioComponent,
      },
      {
        path: 'app/finish',
        component: FinishComponent,
      },
      {
        path: 'app/pap',
        component: PapComponent ,
      },
      {
        path: 'app/papCash',
        component: PapCashComponent,
      },
      {
        path : 'app/finishPap',
        component: FinishPapComponent,
      },
      {
        path: 'consultor/:consultor',
        component: HomeComponent,
      },
      {
        path: '**',
        component: HomeComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    GreetingComponent,
    MainComponent,
    HomeComponent,
    ServicesComponent,
    CardComponent,
    VideosComponent,
    BasicComponent,
    AddressComponent,
    JobComponent,
    SignComponent,
    CongratsComponent,
    BalanceComponent,
    ForeignComponent,
    FinishComponent,
    WowComponent,
    FieldNumberComponent,
    WaitingComponent,
    ErrorComponent,
    IndependientComponent,
    AggrementComponent,
    PensionerComponent,
    StudientComponent,
    LocationsComponent,
    CompaniesComponent,
    CaractComponent,
    FonpenComponent,
    Congrats2Component,
    QrComponent,
    ErrorProcesarOtroMedioComponent,
    NationalityComponent,
    DatePickerComponent,
    PapComponent,
    TargetComponent,
    FinishPapComponent,
    PaymentMethodsComponent,
    PapCashComponent,
    ModalsComponent,
    ModalContinueComponent,
    ProfessionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(routes),
    SoyYoModule,
  ],
  exports: [
  ]
})
export class VinculacionesModule {}
