<form [formGroup]="form">
<app-fonpen [defaultValue]="defaultValue.fonpen" (valueState)="setFonpen($event)" ></app-fonpen> 
<div class="mb-3">
    <label for="resolution" class="form-label text-blue">Fecha de la resolución de pensión</label>
    <input formControlName="resolution" *ngIf="true" type="date" class="form-control" 
        id="resolution" aria-describedby="resolutionHelp"
        (keyup)="emitForm()" (change)="emitForm()" >
</div>  
<div class="mb-3">
    <label for="since" class="form-label text-blue">¿Desde cuándo estás pensionado?</label>
    <input formControlName="since" type="date" class="form-control" id="since" aria-describedby="sinceHelp"
        [ngClass]="{'is-invalid': form.get('since')?.invalid && (isTimeout || form.get('since')?.touched)}"
        (keyup)="emitForm()" (change)="emitForm()" >
    <div *ngIf="form.get('since')?.invalid && (isTimeout || form.get('since')?.touched)" class="text-custom-red">
        Por favor selecciona una fecha válida.
    </div>
    <div *ngIf="(form.controls.since.errors?.pastDate || form.get('since')?.invalid) && (isTimeout || form.get('since')?.touched)"  class="text-custom-red">
        La fecha debe ser menor que la fecha actual.
    </div>
</div>  
<app-field-number [defaultValue]="defaultValue.income" [minValidValue]="400000" (fieldValue)="setIncome($event)" fieldLabel="¿Cuál es tu ingreso por la actividad económica por principal?"></app-field-number>
    <app-profession
        [defaultValue]="defaultValue.codpro"
        [options]="codpros"
        label="¿Cuál es tu profesión u oficio?"
        placeholder="Escribe tu profesión u oficio"
        (selectionChange)="onProfessionSelected($event)">
    </app-profession>
</form>