import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalstorageService } from '../services/localstorage.service';
import { SecurityService } from '../services/security.service';
import { environment } from 'src/environments/environment.development';
import { ContributionsService } from '../services/contributions.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss']
})
export class FinishComponent implements OnInit {
  @ViewChild('modal') modal: ElementRef;
  @ViewChild('infoModal') infoModal: ElementRef;
  @ViewChild('appContainer', { static: true }) appContainer: ElementRef;

  backRoute = '/app/sign';
  nextRoute = '/app/pap';
  phone;
  timer;
  window;
  serverUrl = environment.serverUrl;
  quota: number = 0;

  // Inputs para el componente ModalComponent
  imagePath = 'assets/svg/Pig.svg';
  authorization = true
  targetTxt = 'Ahorro para emergencia';
  h2Text = '¡Dale un impulso extra a tu dinero!';
  h2subTitle = ' Abre tu ahorro programado'
  noteText = 'Planea un ahorro extra al aporte social y camina hacia el cumplimiento de tus propósitos.';
  buttonNextText = 'Abrir nuevo ahorro';
  buttonBackText = 'No, gracias';
  showTermsC = false;

  infoModalimagePath = 'assets/corp/invest.png';
  infoModalauthorization = true
  infoModalh2Text = 'Información';
  infoModalnoteText = `Recuerda que el valor de tu vinculación es de $40.000 salvo que te encuentres en una campaña comercial. El valor de tu aporte mensual es $`;
  subTextHeader = "Al ser asociado, tienes a favor un plan protección el cual  tiene costo de $11.000 (prevision $10.600 y seguro $ 400) cobrado después del tercer mes de vinculado.";
  infoModalbuttonNextText = 'Entendido';
  infoModalshowTermsC = false;

  constructor(
    private router: Router,
    private title: Title,
    private localStorageService: LocalstorageService,
    private security: SecurityService,
    private contributionService : ContributionsService
  ) {
    this.title.setTitle('Onboarding Digital | Finalizar');
    this.phone = this.localStorageService.get('phone');
  }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(currentUrl);
    }
    this.contributionService.getQuota().subscribe({
      next : (response:any)=>{
        this.quota = response.quota;
        const formatQuota = this.formatQuota(this.quota);
        this.infoModalnoteText = this.infoModalnoteText + formatQuota + ".";
        this.showInfoModal();
      }
    })
  }

  downloadPDF() {
    this.security.getPDF().subscribe((response: any) => {
      const file = `${this.serverUrl}/${response.pdfFile}`;
      const url = file;
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = 'vinculacionDigitalFincomercio.pdf'; // Nombre del archivo descargado
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }
  formatQuota(number:number): string {
    return number.toLocaleString('es-ES');
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    
    this.router.navigateByUrl(this.nextRoute);
  }

  finish() {
    localStorage.clear();
    window.location.href = 'https://www.fincomercio.com';
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  showModal() {
    this.modal.nativeElement.style.display = '';
    this.modal.nativeElement.classList.add('modal-show');
    this.appContainer.nativeElement.style.filter = 'blur(5px)';
    this.appContainer.nativeElement.style.pointerEvents = 'none'; // Aplica desenfoque
    
  }

  hideModal() {
    this.modal.nativeElement.classList.remove('modal-show');
    this.modal.nativeElement.style.display = 'none';
    this.appContainer.nativeElement.style.filter = ''; // Elimina el desenfoque
    this.appContainer.nativeElement.style.pointerEvents = ''; // Restaura la capacidad de interacción
  }

  setModal($event: any){
    
    if($event.state == true){
      this.next();
    }else{
      this.hideModal();
    }
  }

  showInfoModal() {
    this.infoModal.nativeElement.style.display = '';
    this.infoModal.nativeElement.classList.add('modal-show');
    this.appContainer.nativeElement.style.filter = 'blur(5px)';
    this.appContainer.nativeElement.style.pointerEvents = 'none'; // Aplica desenfoque
    
  }

  hideInfoModal() {
    this.infoModal.nativeElement.classList.remove('modal-show');
    this.infoModal.nativeElement.style.display = 'none';
    this.appContainer.nativeElement.style.filter = ''; // Elimina el desenfoque
    this.appContainer.nativeElement.style.pointerEvents = ''; // Elimina desenfoque
  }

  setInfoModal($event: any){
    if($event.state == true){
      this.hideInfoModal();
      setTimeout(() => {
        this.showModal();
      }, 8000);
    }
  }
}
