import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AUTHENTICATED_USER_CODES, ENROLLMENT_BIOMETRIC_PHOTO, ENROLLMENT_DOCUMENT_VALIDATE_CODES, ENROLLMENT_REGISTER_DATA_CODES, SoyYoService } from '../../soy-yo/services/soy-yo.service';
import { SecurityService } from '../services/security.service';
import { LogRedebanSoyyoService } from '../services/log-redeban-soyyo.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class BasicComponent implements OnInit, OnDestroy {

  @ViewChild('warning1') warning1: ElementRef;
  @ViewChild('warning0') warning0: ElementRef;
  @ViewChild('terms') terms: ElementRef;
  backRoute = '/app/services';
  nextRoute = '/app/address';
  vinculatedRoute = '/app/finish';
  wowDisplay = false;
  isTimeout = false;
  validatePerson = false;
  validateChange = false;

  form: FormGroup;
  errorMessage: string = '';
  isNextButtonEnabled = false;
  isLoading = false;
  timer;
  hasValidationPerson = false;
  canContinue = true;
  btnStatus = true;
  reload = false;
  timerMs = 20000;
  emailSubscription!: Subscription;
  emailValidate = false;
  isVinculated = false;


  day: number = 0;
  month: number = 0;
  year: number = 0;

  public cameraPermissionStatus: string = '';

  constructor(private router: Router,
              private title: Title,
              private securityService: SecurityService,
              private soyYoService: SoyYoService,
              private logRedebanSoyyoService: LogRedebanSoyyoService,
    ) {
      this.title.setTitle('Onboarding Digital | Datos Básico');
      this.form = new FormGroup({
        fullname: new FormControl('', Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(35),
          this.noNumbersValidator
        ])),
        identificationType: new FormControl('', Validators.required),
        identification: new FormControl('', Validators.compose([
          Validators.required,
          Validators.min(100000),
          Validators.max(10000000000),
        ])),
        phone: new FormControl('', Validators.compose([
          Validators.required,
          Validators.min(3000000000),
          Validators.max(3999999999),
        ])),
        email: new FormControl('', Validators.compose([
          Validators.required,
          Validators.email,
        ])),
        birthday: new FormControl('', Validators.compose([
          Validators.required,
          this.mustBe18YearsOrOlder // tu validador personalizado
        ])),
        cityBorn: new FormControl('', Validators.compose([
          Validators.required,
        ])),
        nationality: new FormControl('', Validators.compose([
          Validators.required,
        ])),
        authorization: new FormControl(false, Validators.compose([
          Validators.requiredTrue
        ])),
      });  
      this.timer = setTimeout(() => {
        this.isTimeout = true;
      }, this.timerMs);

    this.emailSubscription = this.form.get('email')?.valueChanges.subscribe((value) => {
          if (this.form.get('email')?.valid && this.validateEmail(value)) {
            this.emailValidate = true;
          } else {
            this.emailValidate = false;
          }
        });
    }
    
    noNumbersValidator(control: AbstractControl) {
      const hasNumber = /\d/.test(control.value);
      return hasNumber ? { 'hasNumber': true } : null;
    }
  
    redirectToUpdate(){
      window.location.href = "https://testactualizacionesfincomercio.bsccolombia.com/";
    }

    preventNumbers(event: KeyboardEvent) {
      const controlKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];
      if (controlKeys.includes(event.key)) {
        return;
      }
      if (/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }
  
    preventPasteNumbers(event: ClipboardEvent) {
      const clipboardData = event.clipboardData || window['clipboardData'];
      const pastedText = clipboardData.getData('text');
      if (/\d/.test(pastedText)) {
        event.preventDefault();
      }
    }

    validateEmail(email: string): boolean {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }

    mustBe18YearsOrOlder(control: AbstractControl): { [key: string]: boolean } | null {
      if (control.value !== undefined && control.value !== null) {
        const dateValue = new Date(control.value);
        const now = new Date();
        const minimumAge = new Date();
        minimumAge.setFullYear(now.getFullYear() - 18);
    
        if (dateValue > minimumAge) {
          return { 'under18': true };
        }
      }
      return null; // si no hay problema, retorna null
    }    



  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    } else {
      await this.securityService.getTypedPersonData()
        .subscribe({
          next: async (response: any) => {
            if(response) {
              const step = Number(response?.checkpoint?.split('_')[response?.checkpoint?.split('_')?.length - 1]);
              this.form.patchValue({
                fullname: response.fullname,
                identificationType: response.identificationType,
                identification: response.identification,
                phone: response.phone,
                email: response.email,
                birthday: response.birthday,
                cityBorn: response.cityBorn,
                nationality: response.nationalityId,
              });
              if (response.birthday) {
                const date = new Date(response.birthday);
                this.day = date.getDate();
                this.month = date.getMonth() + 1;
                this.year = date.getFullYear();
              }
              if(step > 3) {
                this.form.patchValue({
                  authorization: true,
                });
                this.form.get('fullname')?.disable();
                this.form.get('identificationType')?.disable();
                this.form.get('identification')?.disable();
                this.form.get('phone')?.disable();
                this.form.get('email')?.disable();
                this.form.get('birthday')?.disable();
                this.form.get('cityBorn')?.disable();
                this.form.get('authorization')?.disable();
                this.form.get('nationality')?.disable();
                this.emailValidate = true;
                if(step === 6) {
                  this.canContinue = false;
                }
                this.hasValidationPerson = true;
              }
            }
          },
          error: async (error: any) => {
          }
        });
    }
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
    clearInterval(this.timerCamera);
  }

  timerCamera;

  async verifyAssociateData() {
    this.btnStatus = false;
    const identification = this.form.value.identification;
    const identificationType = this.form.value.identificationType;
    try{
      await this.securityService.getAssociateData(identificationType,identification).subscribe({
        next: async (response: any) => {
          this.isVinculated = true;
        },error: async (error: any) => {
            console.error(error);
            this.showPreAlertCamera();
        }
      });
    } catch (error) {
      console.error('error', error);
    }
  }

  async initCamera() {
    let config = { video: true, audio: false };
    let stream: any = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia(config);
    } catch (err) {
    }
  }

  async checkCameraPermission() {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        if (videoDevices.length > 0) {
        } else {
        }
    } catch (err) {
    }
  }


  async checkCameraPermissionxxx() {
    
    try {
    } catch(err: any) {
    }
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    this.router.navigateByUrl(this.nextRoute);
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  showPreAlertCamera() {
    this.warning0.nativeElement.classList.remove('hidden');
    this.warning0.nativeElement.classList.add('visible');
    this.btnStatus = false;
  }

  requestCameraAccess(): void {
    // Comprobar si el navegador soporta la API de medios de dispositivos
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Solicitar acceso a la cámara
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream: MediaStream) => {
          // Si se concede el acceso, mostrar un mensaje de éxito
          
          this.onSubmit()
          // Puedes usar el stream de video para mostrar en un elemento <video>
          const videoElement = document.querySelector('video');
          if (videoElement) {
            videoElement.srcObject = stream;
            videoElement.play();
          }
        })
        .catch((error: Error) => {
          // Manejar el error si el acceso a la cámara es denegado o falla
          this.btnStatus = true;
          console.error("Error al acceder a la cámara: ", error);
          alert("Error al acceder a la cámara acceso denegado");
        });
    } else {
      // Informar al usuario si el navegador no soporta la API necesaria
      this.btnStatus = true;
      alert("La API de acceso a la cámara no está soportada en este navegador.");
    }
  }
  

  async onSubmit(): Promise<void> {

    this.warning0.nativeElement.classList.remove('visible');
    this.warning0.nativeElement.classList.add('hidden');

    if(this.hasValidationPerson) {
      if(!this.canContinue){
        return;
      }
      this.next();
      return;
    }

    this.isLoading = true;
    const identification = this.form.value.identification;
    const email = this.form.value.email;
    const phone = this.form.value.phone;
    const fullname = this.form.value.fullname;
    const identificationType = this.form.value.identificationType;
    const birthday = this.form.value.birthday;
    const cityBorn = this.form.value.cityBorn;
    const nationalityId = this.form.value.nationality;
    let documentType = 'CC';
    switch(identificationType) {
      case '1':
        documentType = 'CC';
        break;
      case '2':
        documentType = 'CE';
        break;
      case 1:
        documentType = 'CC';
        break;
      case 2:
        documentType = 'CE';
        break;
      default:
        documentType = 'CC';
        break;
    }
    this.isNextButtonEnabled = false;
    try {
      await this.saveData(identificationType, identification, email, phone, fullname, birthday, cityBorn, nationalityId);
    } catch (error) {
      
      if(error?.error?.err == 'nationality is blocked'){
        this.canContinue = false;
        this.failMessage('A007-F: No se puede realizar su vinculación por este canal, por favor acérquese a una oficina o comuníquese con su asesor...');
        this.saveLog('Error', '00-nationality is blocked', null);
        this.ngOnInit();
        return;
      }
      
    }

    this.saveLog('Step', '01-Inicia operacion', null);
    this.isLoading = true;
    const soyYoModel = this.soyYoService;
    soyYoModel.errorTrhowed.subscribe({
      next: async (error: any) => {
        if(error) {
          this.failMessage('106-F: Ha fallado la validación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
          this.saveLog('Error', '106-F-Validacion usuario', error);
        }
      }});
    try {
      this.saveLog('Step', 'A01-soyYoModel.init()', null);
      await soyYoModel.init();
      this.saveLog('Step', 'A02-soyYoModel.checkingCustomerRegister', null);
      await soyYoModel.checkingCustomerRegister(documentType, identification);
      this.saveLog('Step', 'A03-this.createTransaction', null);
      await this.createTransaction(soyYoModel, documentType, identification);
      this.saveLog('Step', 'A04-soyYoModel.isCustomerRegistred()', null);
      if(!soyYoModel.isCustomerRegistred()) {
          this.saveLog('Step', 'A05-soyYoModel.registerUser', null);
          const response = await soyYoModel.registerUser(email, phone, documentType, identification);
          
          if(!response) {
            this.failMessage('106-D: Ha fallado la validación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
            this.saveLog('Error', '106-D-Validacion usuario', response);   
            return;
          }
          this.saveLog('Step', 'A06-soyYoModel.getUserRegisterDataCode()', null);
          switch(soyYoModel.getUserRegisterDataCode()) {
            case ENROLLMENT_REGISTER_DATA_CODES.SUCCESS:
              this.saveLog('Step', 'A07-soyYoModel.captureFaceProcess', null);
              await soyYoModel.captureFaceProcess(email, phone, documentType, identification);
              switch(soyYoModel.getCaptureBiometricFaceData()) {
                case ENROLLMENT_BIOMETRIC_PHOTO.SUCCESS:
                  this.saveLog('Step', 'A08-soyYoModel.activatedUser', null);
                  await soyYoModel.activatedUser();
                  soyYoModel.getActivatedUserData();
                  if(soyYoModel.isActivatedUser()) {
                    this.saveLog('Step', 'A09-this.getTransaction', null);
                    await this.getTransaction(soyYoModel);
                  } else {
                    this.failMessage('106-A: Ha fallado la activación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                    this.saveLog('Error', '106-A-Activacion de usuario', soyYoModel.isActivatedUser());
                  }
                  break;
                case ENROLLMENT_BIOMETRIC_PHOTO.INSUFICIENT_VALIDATION:
                  this.saveLog('Step', 'A10-soyYoModel.enrollmentDocumentValidate', null);
                  await soyYoModel.enrollmentDocumentValidate(email, phone, documentType, identification);
                  this.saveLog('Step', 'A11-soyYoModel.getDocumentValidatedData', null);
                  if(!soyYoModel.isDocumentValidated()) {
                    switch(soyYoModel.getDocumentValidatedData()?.code) {
                      case ENROLLMENT_DOCUMENT_VALIDATE_CODES.FAIL_PROCESS_CAPTURE:
                        this.failMessage('107: Falló el proceso de captura de imagen, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                        this.saveLog('Error', '107-Captura de imagen', soyYoModel.getDocumentValidatedData()?.code);
                        break;
                      case ENROLLMENT_DOCUMENT_VALIDATE_CODES.MAX_INTENT_TRIES:
                        this.failMessage('108: Ha superado el número de intentos, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                        this.saveLog('Error', '108-Numero de intentos superado', soyYoModel.getDocumentValidatedData()?.code);
                        break;
                      default:
                        this.failMessage('109: Verifica tu conexión e intenta de nuevo. Si el problema persiste, acércate a una oficina o comunícate con tu asesor...');
                        this.saveLog('Error', '109-Fallo de conexion', soyYoModel.getDocumentValidatedData()?.code);
                        break;
                      }
                  } else {
                    await soyYoModel.activatedUser();
                    soyYoModel.getActivatedUserData();
                    this.saveLog('Step', 'A12-soyYoModel.isActivatedUser', null);
                    if(soyYoModel.isActivatedUser()) {
                      await this.getTransaction(soyYoModel);
                    } else {
                      this.failMessage('109-A: Ha fallado la activación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                      this.saveLog('Error', '109-A-Activacion de usuario', soyYoModel.isActivatedUser());
                    }
                  }
                  break;
                case ENROLLMENT_BIOMETRIC_PHOTO.FAIL_PHOTO:
                  this.failMessage('110: Falló la captura de la foto, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                  this.saveLog('Error', '110-Captura de foto', soyYoModel.getDocumentValidatedData()?.code);
                  break;
                case ENROLLMENT_BIOMETRIC_PHOTO.MAX_INTENT_TRIES:
                  this.failMessage('111: Superó el número de intentos, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
                  this.saveLog('Error', '111-Numero de intentos superado', soyYoModel.getDocumentValidatedData()?.code);
                default:
                  this.failMessage('112: Verifica tu conexión e intenta de nuevo. Si el problema persiste, acércate a una oficina o comunícate con tu asesor...');
                  this.saveLog('Error', '112-Fallo de conexion', soyYoModel.getDocumentValidatedData()?.code); 
                  break;
              }
              break;
            case ENROLLMENT_REGISTER_DATA_CODES.INCORRECT_DATA:
              this.failMessage('101: La información ingresada no es correcta, corrija e intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
              this.saveLog('Error', '101-Informacion incorrecta', soyYoModel.getDocumentValidatedData()?.code); 
              break;
            case ENROLLMENT_REGISTER_DATA_CODES.USER_ALREADY_REGISTER:
              this.failMessage('102: La información es coincidente con una ingresada, corrija e intente de nuevo o acérquese a una oficina...');
              this.saveLog('Error', '102-Informacion existente', soyYoModel.getDocumentValidatedData()?.code);
              break;
            default:
              this.failMessage('103: VVerifica tu conexión e intenta de nuevo. Si el problema persiste, acércate a una oficina o comunícate con tu asesor...');
              this.saveLog('Error', '103-Fallo de conexion', soyYoModel.getDocumentValidatedData()?.code);
              break;
          }

      } else {
        this.saveLog('step', '03-Validacion usuario', null); 
        await this.authenticatedUserAndGetTransactionListData(soyYoModel, documentType, identification);
      }

    } catch (error) {
      this.failMessage('106-E: Ha fallado la validación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
      this.saveLog('Error', '106-E-Validacion de usuario', error);
      
    } 
  }

  private async saveData(identificationType: any, identification: any, email: any, phone: any, fullname: any, birthday: any, cityBorn: any, nationalityId: any) {
    return new Promise((resolve, reject) => {
      this.securityService.setTypedPersonData(identificationType, identification, email, phone, fullname, birthday, cityBorn, nationalityId)
        .subscribe({
          next: async (response: any) => {
            resolve(response);
          },
          error: async (error: any) => {
            this.validatePerson = true;
          }
        });
      });

  }

  handleModalEvent(event:boolean){
    this.validateChange = event;
  }

  async authenticatedUserAndGetTransactionListData(soyYoModel: SoyYoService, documentType, identification) {
    await soyYoModel.authenticateUser(documentType, identification);
      if(!soyYoModel.isUserAuthenticated()) {
        switch(soyYoModel.getAuthenticatedCode()) {
          case AUTHENTICATED_USER_CODES.USER_NOT_MATCH:
            
            if(soyYoModel.getAuthenticatedUserData()?.data?.description === "No se puede abrir la cámara, dé permiso o reintente la captura") {
              this.failMessage('116A: No se puede acceder a la cámara para iniciar su validación biométrica, por favor asigne los permisos e intente nuevamente...', true);
              this.saveLog('Error', '116-A-Acceso a camara', soyYoModel.getAuthenticatedCode());
            } else {
              this.failMessage('116: Autenticación incorrecta, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
              this.saveLog('Error', '116-Fallo de autenticacion', soyYoModel.getAuthenticatedCode());
            }
            break;
          case AUTHENTICATED_USER_CODES.MAX_INTENT_TRIES:
            this.failMessage('117: Superó el número de intentos para el día de hoy, acérquese a una oficina o comuníquese con su asesor...');
            this.saveLog('Error', '117-Numero de intentos superado', soyYoModel.getAuthenticatedCode());
            break;
          default:
            this.failMessage('118: En este momento no podemos procesar tu solicitud, intenta más tarde. Si el problema persiste, acércate a una oficina o comunícate con tu asesor...');
            this.saveLog('Error', '118-Fallo de conexion', soyYoModel.getAuthenticatedCode());
            break;
        }
      } else {
        await this.getTransaction(soyYoModel);
      }
  }

  private async createTransaction(soyYoModel: SoyYoService, documentType: string, identification: any) {
    await soyYoModel.createTransaction(documentType, identification);
    if (!soyYoModel.isTransactionCreated()) {
      this.failMessage('119: No se pudo crear la transacción, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...');
      this.saveLog('Error', '119-Error transaccion', soyYoModel.isTransactionCreated());
    }
    this.saveLog('Step', '02-createTransaction', soyYoModel.isTransactionCreated());
  }

  private async getTransaction(soyYoModel: SoyYoService) {
    await soyYoModel.checkTramitesAuthorizationGetInformation();
    
    if (!soyYoModel.isTramitesAuthorizationGetInformation()) {
      this.failMessage('120: No es posible continuar porque rechazó la autorización, intente de nuevo y acéptela. En caso de ser necesario, acérquese a una oficina o comuníquese con su asesor...');
      this.saveLog('Error', '120-Rechazo autorizacion', soyYoModel.isTramitesAuthorizationGetInformation());
    } else {
      await soyYoModel.getTramitesListInformation();
      const responseSoyYoData = soyYoModel.getListInformationData();
  
      try {
        // Espera a que la promesa se resuelva antes de continuar
        await this.validateData(responseSoyYoData);
  
        if (!soyYoModel.isListInformationGetting()) {
          this.failMessage('121: Verifica tu conexión e intenta de nuevo. Si el problema persiste, acércate a una oficina o comunícate con tu asesor...');
          this.saveLog('Error', '121-Fallo conexion', soyYoModel.isListInformationGetting());
        } else {
          if (responseSoyYoData?.data?.transactionValidation[0]?.result) {
            const result = JSON.parse(responseSoyYoData.data.transactionValidation[0].result);
            if (result.results[0]?.datos_tsti.length > 0) {
              this.failMessage('122: No es posible continuar con su vinculación por este canal. Por acérquese a una oficina o comuníquese con su asesor...');
              this.canContinue = false;
              this.hasValidationPerson = true;
              this.saveLog('Error', '122-Error de vinculacion', result);
            } else {
              console.warn('OKKKK 1111');
              this.saveLog('Step', '05-wow', result);
              if(!this.validatePerson){
                this.wow();
              }
            }
          } else {
            this.saveLog('Step', '06-wow', null);
            this.wow();
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
  
  private validateData(responseSoyYoData: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.securityService.setValidateData(responseSoyYoData)
        .subscribe({
          next: (response) => {
            resolve();
          },
          error: (err) => {
            console.error(err);
            this.validatePerson = true;
            reject(err);
          }
        });
    });
  }

  reloadPage() {
    window.location.reload();
  }
  

  failMessage(message, relaod = false) {
    this.reload = relaod;
    this.errorMessage = message;
    this.isLoading = false;
    this.isNextButtonEnabled = false;
    this.btnStatus = true;
  }

  wow() {
    this.securityService.setNewPoints('validation', 500)
      .subscribe();
    this.wowDisplay = true;
    this.isLoading = false;
  }

  retry() {
    this.errorMessage = '';

  }

  skip() {
    const identification = this.form.value.identification;
    const email = this.form.value.email;
    const phone = this.form.value.phone;
    const fullname = this.form.value.fullname;
    const identificationType = this.form.value.identificationType;
    const birthday = this.form.value.birthday;
    const cityBorn = this.form.value.cityBorn;
    const nationalityId = this.form.value.nationality;
    this.isNextButtonEnabled = false;
    this.saveData(identificationType, identification, email, phone, fullname, birthday, cityBorn, nationalityId);
    this.wow();
  }

  testxxx() {
    const data = {
      "code": "F102",
      "message": "Transaction found",
      "type": "Success",
      "data": {
        "procedureId": 109,
        "expirationDate": "2023-06-22T09:17:18.000Z",
        "creationDate": "2023-06-21T21:17:18.000Z",
        "state": "AUTHORIZED",
        "userId": 6674,
        "identificationNumber": "80067257",
        "documentType": "CC",
        "levelIdentity": 2,
        "transactionValidation": [
          {
            "validationId": 11,
            "validatorName": "Legal Check",
            "result": "{}",
            "transactionValidationStatusId": 1,
            "transactionValidationStatus": "REGISTERED"
          }
        ],
        "documents": [],
        "failedRedirectUrl": "",
        "successRedirectUrl": "",
        "data": "{\"fieldsData\":[{\"fieldId\":14,\"fieldName\":\"Tipo de documento\",\"fieldValue\":\"CC \"},{\"fieldId\":13,\"fieldName\":\"Número de documento\",\"fieldValue\":\"80067257\"},{\"fieldId\":1,\"fieldName\":\"Primer nombre\",\"fieldValue\":\"JOHAN\"},{\"fieldId\":2,\"fieldName\":\"Segundo nombre\",\"fieldValue\":\"GABRIEL\"},{\"fieldId\":3,\"fieldName\":\"Apellidos\",\"fieldValue\":\"RINCON ALFONSO\"},{\"fieldId\":6,\"fieldName\":\"Fecha de expedición\",\"fieldValue\":\" 11/06/1997 \"},{\"fieldId\":7,\"fieldName\":\"Lugar de expedición\",\"fieldValue\":\"BOGOTA DC - CUNDINAMARCA \"},{\"fieldId\":18,\"fieldName\":\"Numero de celular\",\"fieldValue\":\"+57 3168655624\"},{\"fieldId\":19,\"fieldName\":\"Correo\",\"fieldValue\":\"anivelmundial@hotmail.com\"}]}",
        "userName": "JOHAN GABRIEL RINCON ALFONSO",
        "attribute": "{}",
        "procedureType": 1,
        "originatorNames": "",
        "originatorLastnames": ""
      },
      "traceId": "ac229961-e338-5629-8dd5-8f2396d9220b"
    };

    this.securityService.setValidateData(data as any)
      .subscribe({
        next: (response) => {
          
        },error(err) {
          this.validatePerson = true;
        },
      });

  }

  setLocation($event: number|null) {
    this.form.get('cityBorn')!.setValue($event);
  }

  setNationality($event: number|null) {
    this.form.get('nationality')!.setValue($event);
  }

  setDatePicked($event: string) {
    this.form.get('birthday')!.setValue($event);
  }

  async testingCamera() {
    this.warning0.nativeElement.classList.remove('visible');
    this.warning0.nativeElement.classList.add('hidden');
    const that = this;
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          // const video: HTMLVideoElement = this.videoElement.nativeElement;
          // video.srcObject = stream;
          this.onSubmit();
        })
        .catch(error => {
          this.warning1.nativeElement.classList.remove('hidden');
          this.warning1.nativeElement.classList.add('visible');  
          this.btnStatus = true;
        });
    } else {
      //console.error("getUserMedia no es soportado en este navegador.");
      this.warning1.nativeElement.classList.remove('hidden');
      this.warning1.nativeElement.classList.add('visible');   
}
  }

  allowCamera() {

  }

  hiddeAlert() {
    this.warning1.nativeElement.classList.remove('visible');
    this.warning1.nativeElement.classList.add('hidden');
  }

  showTerms() {
    this.terms.nativeElement.classList.remove('hidden');
    this.terms.nativeElement.classList.add('visible');
  }

  hiddeTerms() {
    this.terms.nativeElement.classList.remove('visible');
    this.terms.nativeElement.classList.add('hidden');
  }

  saveLog(step: string, message: string, data: any) {
    const logRedebanSoyyoModel = this.logRedebanSoyyoService;
    logRedebanSoyyoModel.setLogRedebanSoyyo(step, message, data).subscribe({
      next: (response: any) => {
      },
      error: (error: any) => {
        this.validatePerson = true;
      }
    });
  }

}
