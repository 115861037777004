<form [formGroup]="form">
    <div class="mb-3">
        <label for="location" class="form-label text-blue">{{ label }}</label>
        <input type="text" class="form-control" #location id="location" formControlName="location" aria-describedby="locationHelp" 
          placeholder=""
          [ngClass]="{'is-invalid': !isValid && (isTimeout || form.get('location')?.touched)}"
          (blur)="validateBlur()" 
          (keydown)="makeInvalid()"
          (input)="filterLocations($event)"
          (focus)="filterLocations($event)"
          (keydown.escape)="esc()">

          <ul id="list" class="max-h-[300px] relative overflow-y-scroll mt-2 bg-white border border-gray-200">
            <div class="relative">
              <app-loader *ngIf="isLoading"></app-loader>
              <li class="list-group-item p-2 hover:bg-gray-100 cursor-pointer" *ngFor="let location of filteredLocations" (click)="selectLocation(location.id, location.name)">
                {{ location.name }}
              </li>
            </div>
          </ul>     
        <div *ngIf="!isValid && (isTimeout || form.get('location')?.touched)" class="text-custom-red">Por favor selecciona un elemento de la lista.</div>
    </div>
</form>
