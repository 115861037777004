import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ListService } from '../../services/list.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { beforeDateValidator } from '../../validators/before-date.validator';

@Component({
  selector: 'app-aggrement',
  templateUrl: './aggrement.component.html',
  styleUrls: ['./aggrement.component.scss']
})
export class AggrementComponent implements OnInit, OnChanges {

  codpros: any[] = [];
  filteredOptions: any[] = [];
  form: FormGroup;
  timer: any;
  isTimeout = false;
  @Input() resetTimer = false;
  @Input() defaultValue: any = null;
  @Output() formValues = new EventEmitter();

  constructor(private listService: ListService) {
    this.form = new FormGroup({
      since: new FormControl('', [Validators.required, beforeDateValidator()]),
      code: new FormControl('', []),
      codpro: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      caract: new FormControl('', [Validators.required]),
      income: new FormControl('', [Validators.required]),
    });
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 5000);
   }

  ngOnInit(): void {
      this.listService.getCodpro()
      .subscribe({
        next: (response: any) => {
          this.codpros = response.list;
          this.filteredOptions = this.codpros;  // Inicialmente, todas las opciones están disponibles.
          if(this.defaultValue?.codpro){
            this.form.get('codpro')!.setValue(this.defaultValue.codpro);
            this.emitForm();
          }
          if(this.defaultValue?.since){
            this.form.get('since')!.setValue(this.defaultValue.since);
            this.emitForm();
          }
          if(this.defaultValue?.code){
            this.form.get('code')!.setValue(this.defaultValue.code);
            this.emitForm();
          }
          if(this.defaultValue?.company){
            this.form.get('company')!.setValue(this.defaultValue.company);
            this.emitForm();
          }
          if(this.defaultValue?.caract){
            this.form.get('caract')!.setValue(this.defaultValue.caract);
            this.emitForm();
          }
          if(this.defaultValue?.income){
            this.form.get('income')!.setValue(this.defaultValue.income);
            this.emitForm();
          }
        }
      });
  }

  ngOnChanges(): void {
    if(this.resetTimer) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.isTimeout = true;
      }, 5000);
    }
  }

  setIncome(income) {
    this.form.get('income')!.setValue(income);
    this.emitForm();
  }

  setCompany(companyId) {
    this.form.get('company')!.setValue(companyId);
    this.emitForm();
  }

  setCarac(caractId) {
    this.form.get('caract')!.setValue(caractId);
    this.emitForm();
  }

  setCode() {
    this.emitForm();
  }

  setDate() {
    this.emitForm();
  }

  changeOption() {
    this.emitForm();
  }

    // Filtrar las opciones en base al input del usuario
    filterOptions(): void {
      const query = this.form.get('codpro')?.value.toLowerCase() || '';
      this.filteredOptions = this.codpros.filter(item => item.name.toLowerCase().includes(query));
    }
  
    // Seleccionar una opción y establecerla en el campo de formulario
    selectOption(item: any): void {
      this.form.get('codpro')?.setValue(item.name);  // Puedes cambiar esto si prefieres usar 'item.id'
      this.filteredOptions = [];  // Ocultar las opciones una vez seleccionada una
      this.emitForm();
    }
    
  emitForm() {
    const formValues = this.form.value;
    Object.keys(formValues).forEach(key => {
      if(formValues[key] === '') {
        formValues[key] = null;
      }
    });
    this.formValues.emit(formValues);
  }

  onProfessionSelected(selectedOption: any): void {
    this.form.get('codpro')!.setValue(selectedOption);
    this.emitForm();
  }
}
