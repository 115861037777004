import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '../services/security.service';
import { LocalstorageService } from '../services/localstorage.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  //Cambiar de nuevo al service
  nextRoute = '/app/services';
  consultor: any;
  showConsultorName;
  constructor(private title: Title,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private localstorageService: LocalstorageService,
              private securityService: SecurityService
    ) {
    this.title.setTitle('Onboarding Digital | Inicio');
    const consultor = this.activatedRoute.snapshot.paramMap.get('consultor');
    if(consultor) {
        this.localstorageService.set('consultor', consultor);
        this.securityService.setConsultor(consultor)
          .subscribe({
            next: (response: any) => {
              this.consultor = {
                phone: response.phone,
                name: response.name,
                user: response.user,
                agency: response.agency,
                qrImage: `${environment.serverUrl}/assets/qr/${ response.qrCode }`
              };
              this.showConsultorName = this.consultor != undefined;
            },
            error: (error: any) => {
              console.error('error', error);
            },
          })
        ;
    } else {
      this.securityService.getConsultor()
      .subscribe({
        next: (response: any) => {
          localstorageService.set('qr', `${environment.serverUrl}/assets/qr/${ response.qrCode }`);
          localstorageService.set('phone', response.phone);
          localstorageService.set('consultorName', response.name);
          localstorageService.set('user', response.user);
          localstorageService.set('agency', response.agency);
          this.consultor = {
            phone: response.phone,
            name: response.name,
            user: response.user,
            agency: response.agency,
            qrImage: `${environment.serverUrl}/assets/qr/${ response.qrCode }`
          };
},
        error: (error: any) => {
          const phone = localstorageService.get('phone');
          const consultorName = localstorageService.get('consultorName');
          const qr = localstorageService.get('qr');
          const user = localstorageService.get('user');
          const agency = localstorageService.get('agency');
          this.consultor = {
            phone: phone,
            name: consultorName,
            user: user,
            agency : agency,
            qrImage: qr,
          };
        },
      })
    ;

    }
  }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    }
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    this.router.navigateByUrl(this.nextRoute);
  }

}
