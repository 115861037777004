import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SecurityService } from '../services/security.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})
export class BalanceComponent implements OnInit {

  backRoute = '/app/address';
  nextRoute = '/app/foreign';
  wowDisplay = false;
  form: FormGroup;

  constructor(private router: Router,
              private title: Title,
              private securityService: SecurityService,
    ) {
      this.title.setTitle('Onboarding Digital | Balance');
      this.form = new FormGroup({
        incomeExtraActivity: new FormControl('', [Validators.required]),
        outcome: new FormControl('', [Validators.required]),
        assets: new FormControl('', [Validators.required]),
        liabilities: new FormControl('', [Validators.required]),
      });
    }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(currentUrl);
    } else {
      this.securityService.getBalance().subscribe({
        next: (response: any) => {

          if(response.outcome) {
            this.form.get('outcome')!.setValue(response.outcome);
          }
          if(response.assets) {
            this.form.get('assets')!.setValue(response.assets);
          }
          if(response.liabilities) {
            this.form.get('liabilities')!.setValue(response.liabilities);
          }
          if(response.incomeExtraActivity) {
            this.form.get('incomeExtraActivity')!.setValue(response.incomeExtraActivity);
          }
        }
      });
    }
  }
  async next() {
    localStorage.setItem('route', this.nextRoute);
    this.router.navigateByUrl(this.nextRoute);
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  getGains(): string {
    const incomeExtraActivity = parseFloat(this.form.get('incomeExtraActivity').value);
    const outcome = parseFloat(this.form.get('outcome').value);
    const assets = parseFloat(this.form.get('assets').value);
    const liabilities = parseFloat(this.form.get('liabilities').value);
  
    // Validar que los valores sean números y no NaN
    if (isNaN(incomeExtraActivity) || isNaN(outcome) || isNaN(assets) || isNaN(liabilities)) {
      throw new Error('Todos los campos deben ser números válidos.');
    }
  
    // Calcular las ganancias
    const gains = (incomeExtraActivity - outcome) + (assets - liabilities);
    
  
    // Retornar las ganancias como string
    return gains.toString();
  }

  // wow() {
  //   this.wowDisplay = true;
  // }

  saveData() {
    if(this.form.valid) {
      localStorage.setItem('gains', this.getGains())
      this.securityService.setBalance(this.form.value).subscribe({
        next: (response: any) => {
          this.next();
        }
    });
  }
}

setIncomeExtraActivity(event) {
  this.form.get('incomeExtraActivity')!.setValue(event);
}

setOutcome(event) {
  this.form.get('outcome')!.setValue(event);
}

setAssets(event) {
  this.form.get('assets')!.setValue(event);
}

setLiabilities(event) {
  this.form.get('liabilities')!.setValue(event);
}



}
