<app-header
[backRoute]="backRoute"
></app-header>
  <div class="h-[90vh] flex flex-col items-center pt-[60px] text-center bg-gray-200">
    <div class="container">
      <div class="row">
        <div *ngIf="!isOtpValidated" class="col d-flex justify-content-center mb-4 text-blue font-bold text-4xl">
            Firma tu vinculación
        </div>
        <div *ngIf="isOtpValidated" class="col d-flex justify-content-center mb-4 text-blue font-bold text-4xl">
            La vinculación ya ha sido firmada
        </div>
      </div>
      <div *ngIf="!isOtpValidated" class="row">
        <div class="col text-center">
              
                    <form [formGroup]="form">
                        <div class="mb-3">
                            <label for="address" class="form-label text-gray-500">Hemos enviado a tu número celular registrado un código de seis (6) digitos, ingrésalo aquí para firmar tu vinculación:</label>
                            <input formControlName="code" type="text" class="form-control" id="address" aria-describedby="addressHelp">
                            <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>  
                      </form>
                      <div>Podrá reenviar el código en {{ seconds }} segundos...</div>
                      <!-- <input type="button" class="btn btn-primary h-[2.5rem] w-full mt-2" value="Reenviar código" (click)="resendCode()" [disabled]="seconds > 0"> -->

        </div>
      </div>
  
      <div class="w-full h-10 flex justify-center items-center mt-3">
        <button [disabled]="form.invalid && !isOtpValidated"
          type="button"
          class="btn btn-primary btn-custom"
          (click)="checkCode()"
        >
          Continuar
        </button>
      </div>
      <div *ngIf="seconds == 0" class="row mt-2 p-2 bg-light-blue-100 rounded-md">
        <div class="h-auto w-full bg-light-blue text-black rounded-md flex items-center justify-center text-xs">
          <p class="m-0 w-full text-center py-3 text-xs">Puedes solicitar nuevamente el código
            <span (click)="resendCode()" style="color: #003085; text-decoration: underline; cursor: pointer;">aquí</span>.
          </p>
        </div>
      </div>
    </div>
  </div>
  <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>
  <app-loader *ngIf="loading"></app-loader>
 <app-button-help></app-button-help>
