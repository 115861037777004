import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-shared',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
  @Output() buttonAction = new EventEmitter<any>();
  @Output() buttonBackAction = new EventEmitter<any>();
  @Input() title = "";
  @Input() srcImg = "";
  @Input() text = "";
  @Input() buttonText = "";
  @Input() buttonBack = "";
  @Input() showButtonBack = false;
  
  onButtonClick() {
    this.buttonAction.emit(true);
  }
  onButtonBackClick() {
    this.buttonBackAction.emit(false);
  }
}
