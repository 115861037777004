import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SecurityService } from '../services/security.service';
import { ContributionsService } from '../services/contributions.service';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit, OnDestroy {

  @ViewChild('modal') modal: ElementRef;
  @ViewChild('appContainer', { static: true }) appContainer: ElementRef;


  backRoute = '/app/foreign';
  nextRoute = '/app/finish';
  wowDisplay = false;
  code = '';
  form: FormGroup;
  timer: any;
  seconds = 10;
  isOtpValidated = false;

  loading = false;
  
  constructor(private router: Router,
              private title: Title,
              private securityService: SecurityService,

    ) {
      this.title.setTitle('Onboarding Digital | Firma Vinculación');
      this.form = new FormGroup({
        code: new FormControl('', [Validators.required, Validators.minLength(6)])
      });
    }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(currentUrl);
    } else {
      await this.securityService.getTypedPersonData()
        .subscribe({
          next: async (response: any) => {

          },
          error: async (error: any) => {

          }
        });
      this.getOTP();
    }
  }



  getOTP() {
    this.securityService.sendOTP().subscribe({
      next: (response: any) => {

        if(response.isOtpValidated) {
          this.isOtpValidated = true;
        } else {
          this.seconds = 120;
          this.timer = setInterval(() => {
            this.seconds--;
            if(this.seconds === 0) {
              clearInterval(this.timer);
            }
          }, 1000);
          Swal.fire({
            title: 'Código de verificación',
            text: `Se ha enviado el código de verificación a su celular ${response.startPhone}*****${response.lastPhone}`,
            icon: 'info',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#0096d2',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          });
        }
      }  
    });
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    this.router.navigateByUrl(this.nextRoute);
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  checkCode() {
    this.loading = true;
    if(this.form.valid || this.isOtpValidated) {
      this.securityService.checkOTP(this.form.value.code).subscribe({
        next: async (response: any) => {
          this.loading = false;
          if(!response.isOtpValidated) {
            await Swal.fire({
              title: 'Código de verificación',
              text: `Se ha confirmado el código de verificación`,
              icon: 'success',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#0096d2',
            }).then((result) => {
              if (result.isConfirmed) {
                this.next();
              }
            });
          } else {
            this.next();
          }
        },
        error: (error: any) => {
          this.loading = false;
          console.error(error);
          Swal.fire({
            title: 'Código de verificación',
            text: `El código de verificación es incorrecto`,
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#0096d2',
          })
        },
      });
    }
  }


  wow() {
    this.wowDisplay = true;
  }

  resendCode() {
    this.getOTP();
  }
}
