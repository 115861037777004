import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() backRoute: string = '';
  @Input() showBack: boolean = true;
  consultor = localStorage.getItem("consultorName")

  constructor(private router: Router,){

  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }
}
